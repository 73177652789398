<script>
import { ref, watch } from 'vue';
import { useStore } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import { getValueListById } from '@/helpers/forms/getListaValorById';
import useValueListTable from '@/helpers/dynamicTable/tabelaListaValor';
import { vue3Debounce } from 'vue-debounce'
import { getTitlesFromListValue } from '@/helpers/forms/getTitlesAndCodeNames.js'
import Swal from 'sweetalert2';

export default {
    props: {

    },
    setup() {
        const store = useStore();
        const show = ref(false);
        const searchString = ref('')
        const inputLoading = ref(false)
        const modalListFilterMinDigitShow = ref(false)
        const filteredListOptions = ref([])
        const title = ref(null)
        const valueListTableId = uuidv4()
        const valueListGrid = ref(null)
        const sis_lista_valor = ref(null)
        const saveLoading = ref(false)
        var DYNAMIC_VALUE_LIST_TABLE_FUNCTIONS = ref(null)
        let selected_items = ref([])
        watch(() => store.state.massActionsModals.showDynamicFilterValueListModal, (value) => {
            show.value = value;
        })
        watch(() => show.value, (value) => {
            if(value){
                if(!store.state.massActionsModals.sis_lista_valor.exige_filtro){
                    sis_lista_valor.value = store.state.massActionsModals.sis_lista_valor
                    createTable(store.state.massActionsModals.sis_lista_valor.sis_opcao_lista_valor ?? [])
                }
            }
            if(!value) {
                store.commit('massActionsModals/setProperty', [
                    {
                        key: 'showDynamicFilterValueListModal',
                        value: false
                    },
                    {
                        key: 'tipoAplicacao',
                        value: null
                    }
                ])
                searchString.value = ''
                inputLoading.value = false
                modalListFilterMinDigitShow.value = false
                filteredListOptions.value = []
                title.value = null
                sis_lista_valor.value = null
                saveLoading.value = false
                DYNAMIC_VALUE_LIST_TABLE_FUNCTIONS?.value?.unselectRowsById(
                    selected_items.value
                );
                DYNAMIC_VALUE_LIST_TABLE_FUNCTIONS.value = null
                document.getElementById(valueListTableId).innerHTML = "";

                if(document.getElementsByTagName('body')[0].attributes['data-bs-padding-right'])
                document.getElementsByTagName('body')[0].attributes.removeNamedItem('data-bs-padding-right')
            }
        })
        const createTable = (registros) => {
            const valueListTitles = getTitlesFromListValue(sis_lista_valor.value)
            const columns = valueListTitles?.map((title, index) => {
                return {
                    titulo: title,
                    nome: 'codigo'+[index+1],
                }
            }) || []
            columns.push({
                titulo: 'Denominação',
                nome: 'titulo'
            })

            document.getElementById(valueListTableId).innerHTML = "";
            DYNAMIC_VALUE_LIST_TABLE_FUNCTIONS.value = useValueListTable(
                valueListGrid,
                valueListTableId,
                columns,
                (value) => {
                    selected_items.value = [...value];
                }
            );
            DYNAMIC_VALUE_LIST_TABLE_FUNCTIONS.value.setTableColumns([])
            DYNAMIC_VALUE_LIST_TABLE_FUNCTIONS.value.updateConfig(registros, () => {
                DYNAMIC_VALUE_LIST_TABLE_FUNCTIONS.value.selectRowsById(
                    selected_items.value
                );
            });
        };
        const handleListOptionsFilter = () => {
            if(searchString.value.length < 3){
                modalListFilterMinDigitShow.value = true
                return false
            }
            modalListFilterMinDigitShow.value = false

            const applicationType = store.state.massActionsModals.tipoAplicacao ?? null
            const condition = store.state.massActionsModals.preAditionalCondition ? store.state.massActionsModals.preAditionalCondition + `'${searchString.value}'` : searchString.value
            inputLoading.value = true;
            getValueListById(store.state.massActionsModals.sis_lista_valor.id_lista_valor, condition, applicationType)
            .then(res => {
                if(!res) {
                    inputLoading.value = false
                    return false
                }
                title.value = res.sis_lista_valor.titulo
                filteredListOptions.value = res.sis_lista_valor.sis_opcao_lista_valor
                sis_lista_valor.value = res.sis_lista_valor
                createTable(res.sis_lista_valor.sis_opcao_lista_valor ?? [])
                inputLoading.value = false
            })
            .catch((error) => {
                inputLoading.value = false
                console.log(error)
            })
        }
        const saveSelectedItems = async () => {
            if(!DYNAMIC_VALUE_LIST_TABLE_FUNCTIONS.value || DYNAMIC_VALUE_LIST_TABLE_FUNCTIONS.value.currentSelectedRows.length == 0){
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Nenhum item selecionado!',
                })
                return false
            }
            if(store.state.massActionsModals.maxRows && DYNAMIC_VALUE_LIST_TABLE_FUNCTIONS.value.currentSelectedRows.length > store.state.massActionsModals.maxRows){
                const maxRows = store.state.massActionsModals.maxRows
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: `Permitido selecionar no máximo ${maxRows} ${maxRows == 1 ? 'item' : 'itens'}!`,
                })
                return false
            }
            const register_ids_with_key = selected_items.value.map((id) => {
                return {
                    [sis_lista_valor.value.campo_chave_primaria]: id,
                };
            })
            DYNAMIC_VALUE_LIST_TABLE_FUNCTIONS.value.unselectRowsById(
                selected_items.value
            );
            show.value = false
            saveLoading.value = true
            await store.state.massActionsModals.onSave({[sis_lista_valor.value.nome_tabela]: {registros: register_ids_with_key}})
            saveLoading.value = false
        }

        const removeSelection = async () => {
            DYNAMIC_VALUE_LIST_TABLE_FUNCTIONS.value.unselectRowsById(
                selected_items.value
            );
        };

        return {
            show,
            searchString,
            inputLoading,
            modalListFilterMinDigitShow,
            handleListOptionsFilter,
            title,
            valueListTableId,
            filteredListOptions,
            saveLoading,
            saveSelectedItems,
            selected_items,
            removeSelection,
        };
    },
    directives: {
        debounce: vue3Debounce({ lock: true })
    },
}
</script>

<template>
    <b-modal size="xl" v-model="show" centered scrollable :title="$store.state.massActionsModals?.sis_lista_valor?.titulo" style="z-index: 2051;">
        <div>
            <div class="row mb-4">
                <div class="col-12">
                    <form @submit.prevent>
                        <div class="input-group">
                            <input v-if="$store.state.massActionsModals?.sis_lista_valor?.exige_filtro" type="text" v-model="searchString" v-debounce:300ms.lock.cancelonempty="() => handleListOptionsFilter(searchString)" required minlength="3" class="form-control" placeholder="Buscar...">
                            <button v-if="inputLoading" class="btn">
                                <b-spinner variant="primary" small></b-spinner>
                            </button>
                        </div>
                        <small v-if="modalListFilterMinDigitShow" class="text-danger">A busca deve conter ao menos 3 caracteres</small>
                    </form>
                </div>
            </div>
            <div :id="valueListTableId"></div>
        </div>
        <template #footer>
            <div class="row w-100">
                <div class="btn-group mt-2 col-12 col-md-3" role="group">
                    <button type="button" class="btn btn-warning col-12 col-md-10" @click="openSelectedItemsModal">
                        {{ selected_items.length }} itens selecionados
                    </button>
                    <button type="button" class="btn btn-danger col-12 col-md-2" @click="removeSelection"
                        v-b-tooltip.hover.bottom="'Remover Todos os Itens Selecionados'">
                        <i class="ri-delete-bin-5-line" />
                    </button>
                </div>
                <button type="button" class="mt-2 ms-auto btn btn-primary shadow-sm col-12 col-md-3"
                    @click="saveSelectedItems">
                    <i class="ri-save-2-fill align-bottom me-1" /> Salvar
                </button>
            </div>
        </template>
    </b-modal>
</template>