<script>
import {
  SimpleBar
} from "simplebar-vue3";
import { watch, ref, computed } from 'vue';
import { useStore } from 'vuex'

const showScrollButton = ref(false);
const showScrollLeftButton = ref(false);
const show = ref(false);
const selectedSupplierId = ref(null);
let table = ref(1);
let modalTitle = ref('MELHORES PREÇOS');

export default {
    mounted() {
      this.scrollToTop();
    },
    methods: {
      scrollToTop() {
        this.$nextTick(() => {
          if (this.simpleBarRef && this.simpleBarRef.el) {
            const scrollElement = this.simpleBarRef.el.querySelector('.simplebar-content-wrapper');
            if (scrollElement) {
              scrollElement.scrollTo({
                top: 0,
                behavior: 'smooth',
              });
            } else {
              console.error('Elemento não encontrado: .simplebar-content-wrapper');
            }
          }
        });
      },
      scrollToLeft() {
        this.$nextTick(() => {
          if (this.simpleBarRef && this.simpleBarRef.el) {
            const scrollElement = this.simpleBarRef.el.querySelector('.simplebar-content-wrapper');
            if (scrollElement) {
              scrollElement.scrollTo({
                left: 0,
                behavior: 'smooth',
              });
            } else {
              console.error('Elemento não encontrado: .simplebar-content-wrapper');
            }
          }
        });
      },
      handleScroll(e) {
        const scrollElement = e.target;

        const scrollPosition = scrollElement.scrollTop;
        if (scrollPosition > 100) {
          showScrollButton.value = true;
        } else {
          showScrollButton.value = false;
        }

        const scrollLeft = scrollElement.scrollLeft;
        if (scrollLeft > 100) {
          showScrollLeftButton.value = true;
        } else {
          showScrollLeftButton.value = false;
        }
      },
      changeTables(type) {
        table.value = type

        switch (type) {
          case 1:
            modalTitle.value = 'MELHORES PREÇOS'
            break;
          case 2:
            modalTitle.value = 'MENORES PREÇOS'
            break;
          case 3:
            modalTitle.value = 'MENORES PRAZOS'
            break;
          case 4:
            modalTitle.value = 'MENORES FRETES'
            break;
          case 5:
            modalTitle.value = 'PREÇO TOTAL'
            break;
        }

        this.refrashLoading();
        selectedSupplierId.value = null

      },
      close() {
        this.show = false
      },
      refrashLoading() {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
        }, 1000);
      },
      handleTableClick(tableNumber, price, product) {
        /* Verifica a condição antes de chamar 'changeTables' */
        if(tableNumber == 2) {
          if (price.lowestValue && product.prices.length > 1) {
            this.changeTables(tableNumber);
          }
        } else if(tableNumber == 3) {
          if (price.shorterDeadline && product.prices.length > 1) {
            this.changeTables(tableNumber);
          }
        } else if(tableNumber == 4) {
          if (price.lowestValueShipping && product.prices.length > 1) {
            this.changeTables(tableNumber);
          }
        } else if(tableNumber == 5) {
          if (price.totalValue && product.prices.length > 1) {
            this.changeTables(tableNumber);
          }
        }
      }
    },
    setup(){
        const store = useStore()
        const loading = ref(true);
        const simpleBarRef = ref(null);
        const showTable = ref(false)
        
        watch(() => store.state.massActionsModals.globalLowestPricesModalShow, (value) => {
            show.value = value
            loading.value = value
        })
        watch(() => show.value, (value) => {
          if (value) {
            table.value = 1
            showTable.value = true
            setTimeout(() => {
              loading.value = false;
            }, 2000);
          } else {
            store.commit('massActionsModals/setProperty', [
              {
                key: 'globalLowestPricesModalShow',
                value: false
              }
            ]);
          }
        });
        const selectSupplier = (idFornecedor, totalUniqueCompanies) => {
          /* Atualiza o id_fornecedor selecionado quando o nome da empresa é clicado */
          if(totalUniqueCompanies > 1) {
            table.value = 1;
            selectedSupplierId.value = idFornecedor;
          }
        };
        const selectSupplierBasedOnTable = (product, totalUniqueCompanies) => {
          /* Atualiza 'selectedSupplierId' com base no valor de 'table' */
          switch (table.value) {
            case 2:
              selectedSupplierId.value = product.lowestPrice.id_fornecedor;
              break;
            case 3:
              selectedSupplierId.value = product.shorterDeadline.id_fornecedor;
              break;
            case 4:
              selectedSupplierId.value = product.smallerShipping.id_fornecedor;
              break;
            case 5:
              selectedSupplierId.value = product.totalPrice.id_fornecedor;
              break;
          }

          /* Depois de definir o id_fornecedor, chama a função de seleção */
          selectSupplier(selectedSupplierId.value, totalUniqueCompanies);
        };
        /* Empresas */
        const companies = computed(() => {
          return store.state.massActionsModals.globalLowestPricesModalProperties.companies;
        });
        /* Empresas únicas */
        const uniqueCompanies = computed(() => {
          const seen = new Set();
          return companies.value.reduce((uniqueCompanie, supplier) => {
            if (!selectedSupplierId.value || supplier.id_fornecedor == selectedSupplierId.value) {
              if (!seen.has(supplier.id_fornecedor)) {
                seen.add(supplier.id_fornecedor);
  
                const seenItems = new Set();
        
                /* Filtra os produtos da empresa atual */
                const productsFromTheCompany = companies.value.filter(product => 
                  product.id_fornecedor === supplier.id_fornecedor && 
                  !seenItems.has(product.id_item) && /* Verifica se o id_item já foi adicionado */
                  (seenItems.add(product.id_item) || true) /* Marca o id_item como adicionado */
                );

                /* Calcula o preço total da empresa com base apenas nos produtos únicos */
                const priceTotal = productsFromTheCompany.reduce((total, product) => {
                  /* Soma os valores totais dos produtos únicos */
                  const productPrice = product.total_item;
                  return total + (productPrice ? parseFloat(productPrice) : 0);
                }, 0);
  
                /* Calcula o frete total da empresa com base apenas nos produtos únicos */
                const freightTotal = productsFromTheCompany.reduce((total, product) => {
                  /* Soma os valores totais dos produtos únicos */
                  const productFreight = product.valor_frete;
                  return total + (productFreight ? parseFloat(productFreight) : 0);
                }, 0);

                /* Adiciona a empresa com o preço total ao array de empresas únicas */
                uniqueCompanie.push({
                  ...supplier,
                  priceTotal,
                  freightTotal
                });
              }
            }
            return uniqueCompanie;
          }, []);
        });
        /* Produtos únicos */
        const uniqueProducts = computed(() => {
          const groupedProducts = {};
          let sumMinorsPrices = 0;
          let sumTotalPrices = 0;
          let sumMinorsFreight = 0;
          let mediaDeadlineDelivery = 0;

          companies.value.forEach(product => {
            const key = product.codigo_item;
            /* Não permite repetir o mesmo produto */
            if (!groupedProducts[key]) {
              groupedProducts[key] = {
                codigo_item: product.codigo_item,
                denominacao_item: product.denominacao_item,
                sigla_unidade: product.sigla_unidade,
                quantidade: product.quantidade,
                prices: []
              };
            }
            /* Verifique se o id_item já existe no array de preços */
            const existingPrice = groupedProducts[key].prices.some(price => 
              price.id_item === product.id_item && 
              price.id_fornecedor === product.id_fornecedor
            );
            
            /* Se o id_item não existe, adicione o novo preço */
            if (!existingPrice) {
              if (!selectedSupplierId.value || product.id_fornecedor == selectedSupplierId.value) {
                groupedProducts[key].prices.push({
                  id_item: product.id_item,
                  id_fornecedor: product.id_fornecedor,
                  preco_unitario: product.preco_unitario,
                  total_item: product.total_item,
                  prazo_entrega: product.prazo_entrega ? product.prazo_entrega : 0,
                  valor_frete: product.valor_frete,
                  nome_fornecedor: product.nome_fornecedor
                });
              }
            }
          });

          /* Agora, preenchemos os preços faltantes para garantir que todas as empresas tenham preços */
          Object.values(groupedProducts).forEach(product => {
              /* Para cada empresa, verificamos se existe um preço */
              uniqueCompanies.value.forEach(enterprise => {
                  /*Verifica se a empresa já tem um preço para esse produto */
                  const existingPrice = product.prices.some(price => price.id_fornecedor === enterprise.id_fornecedor);
                  
                  if (!existingPrice) {
                      /* Se não houver preço, insere um preço com valores zerados */
                      product.prices.push({
                          id_item: product.codigo_item, 
                          id_fornecedor: enterprise.id_fornecedor,
                          preco_unitario: '-',
                          total_item: '-',
                          prazo_entrega: '-',
                          valor_frete: '-',
                          lowestValue: false,
                          totalValue: false
                      });
                  }
              });
              
              /* Ordena os preços para garantir que a ordem das empresas seja mantida */
              product.prices.sort((a, b) => {
                  const indexA = uniqueCompanies.value.findIndex(emp => emp.id_fornecedor === a.id_fornecedor);
                  const indexB = uniqueCompanies.value.findIndex(emp => emp.id_fornecedor === b.id_fornecedor);
                  return indexA - indexB;
              });

              /* Encontre o menor valor entre os preços e marque o menor como `lowestValue = true` */
              const priceMinimumValid = Math.min(...product.prices.filter(price => price.preco_unitario != '-' && price.preco_unitario > 0).map(price => price.preco_unitario));
              product.prices.forEach(price => {
                price.lowestValue = price.preco_unitario === priceMinimumValid && price.preco_unitario != '-' && price.preco_unitario > 0;
              });

              /* Encontre o menor valor total entre os preços e marque o menor como `totalValue = true` */
              const totalPriceMinimumValid = Math.min(...product.prices.filter(price => price.total_item != '-' && price.total_item > 0).map(price => price.total_item));
              product.prices.forEach(price => {
                price.totalValue = price.total_item === totalPriceMinimumValid && price.total_item != '-' && price.total_item > 0;
              });

              /* Encontre o menor valor de frete entre os preços e marque o menor como `lowestValueShipping = true` */
              const priceMinimumValidShipping = Math.min(...product.prices.filter(price => price.valor_frete != '-' && price.valor_frete > 0).map(price => price.valor_frete));
              product.prices.forEach(price => {
                price.lowestValueShipping = price.valor_frete === priceMinimumValidShipping && price.valor_frete != '-' && price.valor_frete > 0;
              });

              /* Encontre o menor prazo de entrega entre os prazos e marque o menor como `shorterDeadline = true` */
              const deadlineMinimumValid = Math.min(...product.prices.filter(term => term.prazo_entrega != '-' && term.prazo_entrega > 0).map(term => term.prazo_entrega));
              product.prices.forEach(term => {
                term.shorterDeadline = term.prazo_entrega === deadlineMinimumValid && term.prazo_entrega != '-' && term.prazo_entrega > 0;
              });

              /* Menor preço */
              const lowestPrice = product.prices.filter(price => price.lowestValue)[0];
              if (lowestPrice) {
                product.lowestPrice = {
                  nome_fornecedor: lowestPrice.nome_fornecedor,
                  preco_unitario: lowestPrice.preco_unitario,
                  id_fornecedor: lowestPrice.id_fornecedor
                };

                if (lowestPrice.preco_unitario !== '-' && lowestPrice.preco_unitario > 0) {
                  sumMinorsPrices += parseFloat(lowestPrice.preco_unitario);
                }
              }
              /* Preço Total */
              const totalPrice = product.prices.filter(price => price.totalValue)[0];
              if (totalPrice) {
                product.totalPrice = {
                  nome_fornecedor: totalPrice.nome_fornecedor,
                  total_item: totalPrice.total_item,
                  id_fornecedor: totalPrice.id_fornecedor
                };

                if (totalPrice.total_item !== '-' && totalPrice.total_item > 0) {
                  sumTotalPrices += parseFloat(totalPrice.total_item);
                }
              }
              /* Menor frete */
              const smallerShipping = product.prices.filter(price => price.lowestValueShipping)[0];
              if (smallerShipping) {
                product.smallerShipping = {
                  nome_fornecedor: smallerShipping.nome_fornecedor,
                  valor_frete: smallerShipping.valor_frete,
                  id_fornecedor: smallerShipping.id_fornecedor
                };

                if (smallerShipping.valor_frete !== '-' && smallerShipping.valor_frete > 0) {
                  sumMinorsFreight += parseFloat(smallerShipping.valor_frete); 
                }
              }
              /* Menor prazo */
              const shorterDeadline = product.prices.filter(price => price.shorterDeadline)[0];
              if (shorterDeadline) {
                product.shorterDeadline = {
                  nome_fornecedor: shorterDeadline.nome_fornecedor,
                  prazo_entrega: shorterDeadline.prazo_entrega,
                  id_fornecedor: shorterDeadline.id_fornecedor
                };

                if (shorterDeadline.prazo_entrega !== '-' && shorterDeadline.prazo_entrega > 0) {
                  mediaDeadlineDelivery += parseFloat(shorterDeadline.prazo_entrega);
                }
              }
          });

          mediaDeadlineDelivery = mediaDeadlineDelivery / Object.values(groupedProducts).length;

          return { 
            products: Object.values(groupedProducts),
            sumMinorsPrices: sumMinorsPrices,
            sumTotalPrices: sumTotalPrices,
            sumMinorsFreight: sumMinorsFreight,
            mediaDeadlineDelivery: mediaDeadlineDelivery
          };
        });
        /* Nome Empresa */
        const nameCompany = computed(() => {
          return store.state.massActionsModals.globalLowestPricesModalProperties?.nameCompany || 'EMPRESA NÃO DEFINIDA';
        });
        /* Número cotação */
        const numberQuote = computed(() => {
          return 'Nº COTAÇÃO: ' + store.state.massActionsModals.globalLowestPricesModalProperties?.numberQuote || 'Nº COTAÇÃO INDEFINIDO';
        });   
        /* Formata valores monetários */
        const formatCurrency = (valor) => {
          if (valor === '-') return '-';
          return valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
        };
        /* Formata valores inteiros */
        const formatInteger = (valor) => {
          if (valor === 0) return '0';
          return parseFloat(valor).toFixed(2);
        };
        /* Valor empresa formatado*/
        const valueSupplierFormatted = computed(function() {
          return (product) => {
            if (table.value === 2) {
              return product.lowestPrice ? formatCurrency(product.lowestPrice.preco_unitario) : 'R$ 0,00';
            } else if (table.value === 3) {
              return product.shorterDeadline ? product.shorterDeadline?.prazo_entrega : '0';
            } else if (table.value === 4) {
              return product.smallerShipping ? formatCurrency(product.smallerShipping.valor_frete) : 'R$ 0,00';
            } else {
              return product.totalPrice ? formatCurrency(product.totalPrice.total_item) : 'R$ 0,00';
            }
          };
        });
        /* Nome empresa formatado */
        const nameSupplierFormatted = computed(() => {
          return (product) => {
            if (table.value === 2) {
              return product.lowestPrice ? product.lowestPrice.nome_fornecedor : 'NÃO DEFINIDA';
            } else if (table.value === 3) {
              return product.shorterDeadline ? product.shorterDeadline?.nome_fornecedor : 'NÃO DEFINIDA';
            } else if (table.value === 4) {
              return product.smallerShipping ? product.smallerShipping.nome_fornecedor : 'NÃO DEFINIDA';
            } else {
              return product.totalPrice ? product.totalPrice.nome_fornecedor : 'NÃO DEFINIDA';
            }
          };
        });
        /* Total empresa formatado */
        const totalSuppliersFormatted = computed(function() {
          return (uniqueProducts) => {
            if (table.value === 2) {
              return formatCurrency(uniqueProducts.sumMinorsPrices);
            } else if (table.value === 3) {
              return formatInteger(uniqueProducts.mediaDeadlineDelivery);
            } else if (table.value === 4) {
              return formatCurrency(uniqueProducts.sumMinorsFreight);
            } else {
              return formatCurrency(uniqueProducts.sumTotalPrices);
            }
          };
        });

        return {
          loading,
          show,
          uniqueProducts,
          uniqueCompanies,
          showScrollButton,
          showScrollLeftButton,
          simpleBarRef,
          modalTitle,
          nameCompany,
          numberQuote,
          table,
          showTable,
          nameSupplierFormatted,
          valueSupplierFormatted,
          totalSuppliersFormatted,
          formatCurrency,
          formatInteger,
          selectSupplier,
          selectedSupplierId,
          selectSupplierBasedOnTable,
        }
    },
    components: {
      SimpleBar,
    },
}

</script>
<template>
    <b-modal id="globalLowestPricesModalModal" title="MELHORES PREÇOS" fade scrollable centered size="sizePrices" hide-header hide-footer v-model="show">
      
      <div class="modal-header mt-0 d-flex justify-content-between align-items-center w-100 pt-2 pb-2">
        <span class="logo-lg">
          <img src="/img/ApoioBranco.611486a7.png" alt="Logo" height="50"> 
        </span>
        <h5 class="modal-title">{{ modalTitle }} | {{ nameCompany }} | {{ numberQuote }}</h5>
        <b-button variant="close" class="btn-close" @click="close"></b-button>
      </div>
    
        <div class="d-grid gap-2 p-4" style="position: relative;">
          <div v-if="loading" class="loading-overlay text-center">
              <b-spinner variant="success" />
          </div>
          <SimpleBar v-if="showTable" ref="simpleBarRef" data-simplebar style="max-height: 480px;" @scroll="handleScroll">
            <table v-if="table == 1" class="table table-bordered">
              <thead>
                  <tr :class="['bg-light']">
                      <th colspan="1">{{ uniqueCompanies.length }} {{ uniqueCompanies.length == 1 ? 'FORNECEDOR' : 'FORNECEDORES' }} </th>
                      <th colspan="1"></th>
                      <th colspan="1"></th>
                      <th colspan="4" :class="['nowRap', {'pointer': uniqueCompanies.length > 1 && selectedSupplierId != supplier.id_fornecedor}]" v-for="(supplier, index) in uniqueCompanies" :key="index" @click="selectSupplier(supplier.id_fornecedor, uniqueCompanies.length)">{{ supplier.nome_fornecedor }}</th>
                  </tr>
                  <tr :class="['bg-white, italic']">
                    <th :class="['bg-white']">{{ uniqueProducts.products.length }} {{ uniqueProducts.products.length == 1 ? 'PRODUTO ENCONTRADO' : 'PRODUTOS ENCONTRADOS' }} </th>
                    <th :class="['text-center']">UNIDADE</th>
                    <th :class="['text-center']">QUANTIDADE</th>
                    <template v-for="(supplier, index) in uniqueCompanies" :key="'unit-' + index">
                      <th :class="['text-center', 'nowRap']">PREÇO UNITÁRIO</th>
                      <th :class="['text-center', 'nowRap']">FRETE</th>
                      <th :class="['text-center', 'nowRap']">PREÇO TOTAL</th>
                      <th :class="['text-center', 'nowRap']">PRAZO ENTREGA</th>
                    </template>
                </tr>
              </thead>
                <tbody>
                    <tr v-for="(product, index) in uniqueProducts.products" :key="'product-' + index" :class="{'bg-light': index % 2 === 0, 'bg-white': index % 2 !== 0}">
                      <th :class="['nowRap']">{{ product.codigo_item }} - {{ product.denominacao_item }}</th>
                      <td :class="['text-center']">{{ product.sigla_unidade }}</td>
                      <td :class="['text-center']">{{ product.quantidade }}</td>
                      
                      <template v-for="(price, index) in product.prices" :key="'price-' + index">
                        <th @click="handleTableClick(2, price, product)" :class="['text-center', {'text-success pointer scale': price.lowestValue && product.prices.length > 1}]">{{ formatCurrency(price.preco_unitario) }}</th>
                        <th @click="handleTableClick(4, price, product)" :class="['text-center', {'text-danger pointer scale': price.lowestValueShipping && product.prices.length > 1}]">{{ formatCurrency(price.valor_frete) }}</th>
                        <th @click="handleTableClick(5, price, product)" :class="['text-center', {'text-primary pointer scale': price.totalValue && product.prices.length > 1}]">{{ formatCurrency(price.total_item) }}</th>
                        <th @click="handleTableClick(3, price, product)" :class="['text-center', {'text-warning pointer scale': price.shorterDeadline && product.prices.length > 1}]">{{ price.prazo_entrega }}</th>
                      </template>
                  </tr>
                </tbody>
                <tfoot>
                  <tr :class="['bg-white, italic']">
                    <th :class="['bg-white']" colspan="1">TOTAL</th>
                    <th colspan="1"></th>
                    <th colspan="1"></th>
                    <template v-for="(supplier, index) in uniqueCompanies" :key="'unit-' + index">
                      <th :class="['text-center']"></th>
                      <th :class="['text-center bold']">{{ formatCurrency(supplier.freightTotal) }}</th>
                      <th :class="['text-center bold']">{{ formatCurrency(supplier.priceTotal) }}</th>
                      <th :class="['text-center']"></th>
                    </template>
                  </tr>
                </tfoot>
            </table>

            <table v-if="table > 1" class="table table-bordered">
              <thead>
                  <tr :class="['bg-white italic']">
                    <th>{{ uniqueProducts.products.length }} {{ uniqueProducts.products.length == 1 ? 'PRODUTO ENCONTRADO' : 'PRODUTOS ENCONTRADOS' }} </th>
                    <th :class="['text-center']">UNIDADE</th>
                    <th :class="['text-center']">QUANTIDADE</th>
                    <th :class="['text-left']">EMPRESA</th>
                    <th v-if="table > 1" :class="[ table === 2 ? 'text-right' : table === 3 ? 'text-center' : table === 4 ? 'text-right' : 'text-right', 'nowRap', 'w-160'
                    ]"> {{ table === 2 ? 'PREÇO UNITÁRIO' : table === 3 ? 'PRAZO ENTREGA' : table === 4 ? 'PREÇO FRETE' : 'PREÇO TOTAL' }} </th>
                </tr>
              </thead>
                <tbody>
                    <tr v-for="(product, index) in uniqueProducts.products" :key="'product-' + index" :class="{'bg-light': index % 2 === 0, 'bg-white': index % 2 !== 0}">
                      <th :class="['nowRap']">{{ product.codigo_item }} - {{ product.denominacao_item }}</th>
                      <td :class="['text-center']">{{ product.sigla_unidade }}</td>
                      <td :class="['text-center']">{{ product.quantidade }}</td>
                      <td v-if="table > 1" :class="['text-left', { 'pointer': uniqueCompanies.length > 1 }]" @click="selectSupplierBasedOnTable(product, uniqueCompanies.length)">
                        {{ nameSupplierFormatted(product) }}
                      </td>
                      <th v-if="table > 1" :class="[ table === 2 ? 'text-right text-success' : table === 3 ? 'text-center text-warning' : table === 4 ? 'text-right text-danger' : 'text-right text-primary', 'minW-170']">
                        {{ valueSupplierFormatted(product) }}
                      </th>
                  </tr>
                </tbody>
                <tfoot>
                  <tr :class="['bg-white italic']">
                    <th colspan="1"></th>
                    <th colspan="1"></th>
                    <th colspan="1"></th>
                    <th :class="['text-right']">{{ table == 3 ? 'MÉDIA EM DIAS' : 'TOTAL'}}</th>
                    <th v-if="table > 1" :class="[ 'bold', table === 2 ? 'text-right text-success' : table === 3 ? 'text-center text-warning' : table === 4 ? 'text-right text-danger' : 'text-right text-primary']">
                        {{ totalSuppliersFormatted(uniqueProducts) }}
                    </th>
                  </tr>
                </tfoot>
            </table>
          </SimpleBar>
          <template style="display: flex;">
            <div class="text-muted small" style="max-width: 300px;">
              <div :class="['pointer lowestPrice', {'text-success': table === 2}]" @click="changeTables(2)"><span class="text-success">●</span> Menor preço</div>
              <div :class="['pointer totalPrice', {'text-primary': table === 5}]" @click="changeTables(5)"><span class="text-primary">●</span> Preço Total</div>
              <div :class="['pointer shorterDeadline', {'text-warning': table === 3}]" @click="changeTables(3)"><span class="text-warning">●</span> Menor prazo de entrega (dias)</div>
              <div :class="['pointer smallerShipping', {'text-danger': table === 4}]" @click="changeTables(4)"><span class="text-danger">●</span> Menor frete</div>
            </div>
            <b-button :class="['btn-scroll-top', { 'show': showScrollButton }]" @click="scrollToTop" variant="primary">
              <i class="mdi mdi-arrow-up-thin"></i>
            </b-button>
            <b-button :class="['btn-scroll-left', { 'show': showScrollLeftButton }]" @click="scrollToLeft" variant="primary">
              <i class="mdi mdi-arrow-left-thin"></i>
            </b-button>
            <b-button v-if="table > 1 || selectedSupplierId" :class="['btn btn-sm btn-primary w-150 ms-auto mr-1']" @click="changeTables(1)">
              <i :class="['mdi', { 'mdi-broom': selectedSupplierId }, { 'mdi-broom': !selectedSupplierId }]"></i> {{ selectedSupplierId ? 'Limpar' : 'Limpar' }}
            </b-button>
            <b-button :class="['btn btn-sm btn-success w-150', {'ms-auto': table === 1 && !selectedSupplierId}]" @click="close">
                Fechar
            </b-button>
          </template>
        </div>
    </b-modal>
</template>


<style scoped>
.mr-1 {
  margin-right: 10px;
}

.btn {
  font-size: 1rem;
}

.pointer {
  cursor: pointer;
  transition: all .3s ease;
}

.pointer:hover {
  color: #878a99;
  transition: all .3s ease;
}

.lowestPrice:hover {
  color: rgba(var(--vz-success-rgb), var(--vz-text-opacity)) !important;
  transition: all .3s ease;
}

.totalPrice:hover {
  color: rgba(var(--vz-primary-rgb), var(--vz-text-opacity)) !important;
  transition: all .3s ease;
}

.shorterDeadline:hover {
  color: rgba(var(--vz-warning-rgb), var(--vz-text-opacity)) !important;
  transition: all .3s ease;
}

.smallerShipping:hover {
  color: rgba(var(--vz-danger-rgb), var(--vz-text-opacity)) !important;
  transition: all .3s ease;
}

.nowRap {
  white-space: nowrap !important;
}

.text-right {
  text-align: right;
}

.w-150 {
  width: 150px !important;
}

.w-160 {
  width: 160px;
}

thead {
  position: sticky;
  top: 0px;
  background: #fff;
  z-index: 2;
}

tfoot {
  position: sticky;
  bottom: -1px;
  background: #fff;
  z-index: 2;
}

.table-bordered thead > tr {
    border-width: 0 !important;
}

.simplebar-content-wrapper {
  visibility: visible !important;
}

.table-bordered td:first-child, .table-bordered th:first-child {
    position: sticky;
    left: -1px;
    z-index: 1;
}

.table-bordered .bg-light td:first-child, .table-bordered .bg-light th:first-child {
    background-color: rgba(var(--vz-light-rgb), var(--vz-bg-opacity)) !important;
}

.table-bordered .bg-white td:first-child, .table-bordered .bg-white th:first-child {
    background: #fff;
}

.btn-scroll-top {
  z-index: 10;
  position: fixed;
  bottom: 50px;
  left: 52%;
  transform: translateX(-50%);
  width: 50px;
  height: 50px;
  background-color: #007bff;
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease;
  opacity: 0;
  pointer-events: none;
  font-size: 24px;
  cursor: pointer;
}

.btn-scroll-top.show {
  opacity: 1;
  pointer-events: auto;
  transition: opacity 0.3s ease;
}

.btn-scroll-top:hover {
  opacity: 0.8;
  transition: opacity 0.3s ease;
}

.btn-scroll-left {
  z-index: 10;
  position: fixed;
  bottom: 50px;
  left: 48%;
  transform: translateX(-50%);
  width: 50px;
  height: 50px;
  background-color: #007bff;
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease;
  opacity: 0;
  pointer-events: none;
  font-size: 24px;
  cursor: pointer;
}

.btn-scroll-left.show {
  opacity: 1;
  pointer-events: auto;
  transition: opacity 0.3s ease;
}

.btn-scroll-left:hover {
  opacity: 0.8;
  transition: opacity 0.3s ease;
}

.minW-170 {
  min-width: 170px;
}

.loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.scale {
  transition: all .3s ease;
}

.scale:hover {
  transition: all .3s ease;
  scale: 1.2;
}

.italic th {
  font-style: italic;
  color: #878a99;
  font-weight: normal;
}

.bold {
  font-weight: bold !important;
}
</style>