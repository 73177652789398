<script>
import { useStore } from 'vuex'
import ContasPgarTemplate from './contas_pagar_template.vue';
import ContasPgarPadraoCriacao from './contas_pagar_padrao_criacao.vue';

export default {
    props: {
        program_schema: {
            type: Object,
            required: true,
        },
        dados: {
            type: Object,
            required: true,
        },
        registro_id: {
            type: String,
            required: false,
        },
    },
    setup() {
        const store = useStore()
        const formType = store.state.programForm.formType
    
        return {
            formType,
        }
    },
    components: {
        ContasPgarTemplate,
        ContasPgarPadraoCriacao,
    },
}
</script>

<template>
    <div>
        <ContasPgarPadraoCriacao v-if="formType == 'creation'" :program_schema="program_schema" :dados="dados" :registro_id="registro_id" />
        <ContasPgarTemplate v-else :program_schema="program_schema" :dados="dados" :registro_id="registro_id" />
    </div>
</template>