<script>
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import "flatpickr/dist/flatpickr.css"
import { defineAsyncComponent, markRaw, onMounted, onUnmounted, ref, watch } from 'vue'
import { sendFunctionRequest } from '@/helpers/dynamicTable/sendFunctionRequest'
import Swal from 'sweetalert2'
import AzureHelper from '@/helpers/azure'
import { downloadDocument } from '@/helpers/donwloadDocument'
import DocumentsVisualizationModal from '@/components/tabela_detalhe/documentsVisualizationModal.vue'
import uploadFile from '@/components/file/upload-file.vue'
import moment from 'moment'
import ChatBoxController from '@/components/chat/chat-box-controller'
import { computed } from 'vue'
import ChatBox from "@/components/chat/chat-box.vue";
import QrcodeVue from 'qrcode.vue'
import FunctionButtons from '@/components/tabela_detalhe/functionButtons.vue'
import { callFunction } from '@/helpers/callFunctionFormulario'
import SimpleLoadigPage from '@/components/custom-components/simpleLoadingPage.vue'
import ContasPagarContainer from '@/components/financeiro/movimentacao/contas_pagar/container.vue'
import { Portuguese as PortugueseLocale } from "flatpickr/dist/l10n/pt";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { getValueListById } from '@/helpers/forms/getListaValorById';
import { ApiService } from "@/helpers/api.service";
import putPrimaryKeyInFirstIndex from "@/helpers/putPrimaryKeyInFirstIndex";
import { v4 as uuidv4 } from 'uuid';
import useValueListTable from '../../../../helpers/dynamicTable/tabelaListaValor'
import { getTitlesFromListValue } from '@/helpers/forms/getTitlesAndCodeNames.js'

export default {
    props: {
        program_schema: {
            type: Object,
            required: true,
        },
        dados: {
            type: Object,
            required: true,
        },
        registro_id: {
            type: String,
            required: false,
        },
    },
    setup(props) {
        const route = useRoute()
        const store = useStore()
        const router = useRouter()

        const programa = {
            program_schema: props.program_schema,
            dados: props.dados,
            registro_id: props.registro_id
        }

        const jurisdictions = computed(() => {
            const campo_tabela = programa.program_schema.sis_tabela.sis_campo_tabela.find(sct => sct.id_lista_edicao === 'ba3e815d-88fc-401f-b310-43c60a732620')
            return campo_tabela.sis_lista_edicao.sis_opcao_lista_valor
        })
        const enterprises = computed(() => {
            const campo_tabela = programa.program_schema.sis_tabela.sis_campo_tabela.find(sct => sct.id_lista_edicao === '83c05a92-5148-44a7-9703-9261f7d5cf11')
            return campo_tabela.sis_lista_edicao.sis_opcao_lista_valor
        })
        const natures = computed(() => {
            const campo_tabela = programa.program_schema.sis_tabela.sis_campo_tabela.find(sct => sct.id_lista_edicao === '9f418eeb-80e4-43cd-bff4-5d1bf8205123')
            return campo_tabela.sis_lista_edicao.sis_opcao_lista_valor
        })
        const chargeTypes = computed(() => {
            const campo_tabela = programa.program_schema.sis_tabela.sis_campo_tabela.find(sct => sct.id_lista_edicao === '4c8d1b30-6479-48d3-aa08-de583c2b2911')
            return campo_tabela.sis_lista_edicao.sis_opcao_lista_valor
        })

        const primaryKeyName = programa.program_schema.sis_tabela.sis_campo_tabela.find(
        (col) => col.chave_primaria
        ).nome;
        const registerTargetId = programa.dados[programa.program_schema.sis_tabela.nome_tabela].registros[0][primaryKeyName]        
        const chatController = ref(new ChatBoxController(programa.program_schema, programa.dados, route.params.targetId, registerTargetId))
        chatController.value.setMovimentoMessages(props.dados.sis_anotacao?.registros || [])
        const handleSubmitMessage = (message, id) => chatController.value.handleSubmitMessage(message, id)
        const handleDeleteMessage = (id) => chatController.value.handleDeleteMessage(id)
        const movimentoMessages = computed(() => chatController.value.getMessages())

        const financial_title = ref(null)
        const documents = ref([])
        const comments = ref([])
        const system_comments = ref([])
        const loading_financial_title = ref(false)
        const current_user = JSON.parse(localStorage.getItem('sis_config')).sis_usuario
        const comment_to_save = ref('')
        const temp_financial_title = ref(null)
        const default_body = {
            id: route.query.registro_id,
            id_programa: route.params.targetId,
            condicao_adicional: "",
            lista_id_empresa: JSON.parse(localStorage.getItem('companiesGlobalFilter')) ?? [],
            data_trabalho: JSON.parse(localStorage.getItem('dataTrabalho')),
            navegador: false,
            ordenacao: null,
            quantidade_registros: 100,
            pagina: 0,
        }
        const filesToShow = ref([])
        const filesToShow2 = ref([])
        const showFileModal = ref(false)
        const showFileModal2 = ref(false)
        const currentOriginalFiles = ref([])
        const currentOriginalFiles2 = ref([])
        const showUploadFileModal = ref(false)
        const loadingFileUpload = ref(false)
        const files = ref([])
        const showQrCode = ref(false)
        const loadingTitle = ref('')
        // const invoicesAvailable = ref([])
        const dynamicGridTable = ref(null)
        const dynamicGridTableId = uuidv4()
        const DYNAMIC_VALUE_LIST_TABLE_FUNCTIONS = ref(null)
        const loadingDynamicTable = ref(false)
        const showDynamicGridTable = ref(false)
        const showInputText = ref(false)
        const saveLoading = ref(false)
        const stringToBeSaved = ref(null)

        const add_comment_function = ref(programa.program_schema.sis_funcionalidade.find(sf => sf.id_funcionalidade == '4cbc785e-81ca-4f1c-8fcd-a751e3f0535d'))
        const function_show_document = ref(programa.program_schema.sis_funcionalidade.find(sf => sf.id_funcionalidade == '3e95322a-8f41-4c21-8bfa-9c9908cdbeac'))
        const function_download_document = ref(programa.program_schema.sis_funcionalidade.find(sf => sf.id_funcionalidade == 'b9d6d7c6-34e6-44b2-a846-147306fe1c23'))
        const function_remove_document = ref(programa.program_schema.sis_funcionalidade.find(sf => sf.id_funcionalidade == '07fdec1e-448a-4d9a-90ea-4fc6f3e16b6d'))
        const function_add_document = ref(programa.program_schema.sis_funcionalidade.find(sf => sf.id_funcionalidade == 'dd34d8e4-d993-4e6b-8c99-a411a959550a'))
        const function_bank_slip_config = ref(programa.program_schema.sis_funcionalidade.find(sf => sf.id_funcionalidade == '6d22f883-df90-4d94-9077-4d0ce3ec516a'))
        const function_pix_config = ref(programa.program_schema.sis_funcionalidade.find(sf => sf.id_funcionalidade == '84a4e6a3-967d-44ec-92bf-517002fb6c4a'))
        const function_ted_doc_config = ref(programa.program_schema.sis_funcionalidade.find(sf => sf.id_funcionalidade == '75c360a4-80b8-481a-ab91-41e9d303c409'))
        const function_tax_config = ref(programa.program_schema.sis_funcionalidade.find(sf => sf.id_funcionalidade == '1de8c5d7-fb67-41d8-b990-eb76ccc6c115'))
        const function_qrcode_config = ref(programa.program_schema.sis_funcionalidade.find(sf => sf.id_funcionalidade == 'c8d1240f-680d-49c2-bf18-64d53ef47430'))
        const function_generate_receipt = ref(programa.program_schema.sis_funcionalidade.find(sf => sf.id_funcionalidade == 'ffafbfeb-a936-4087-9d76-ad4b3c18dd57'))
        const function_approve_payment = ref(programa.program_schema.sis_funcionalidade.find(sf => sf.id_funcionalidade == 'bc46e597-ada3-45db-b1c9-95726e764b9c'))
        const function_repprove_payment = ref(programa.program_schema.sis_funcionalidade.find(sf => sf.id_funcionalidade == '636b7c40-7ef4-42d4-b064-f0e2d7eb7ee8'))
        const function_update_bank_slip = ref(programa.program_schema.sis_funcionalidade.find(sf => sf.id_funcionalidade == 'db8ce73e-caef-4f75-a04a-e3e91d97c487'))
        const function_delete_occurrence = ref(programa.program_schema.sis_funcionalidade.find(sf => sf.id_funcionalidade == '5d83e846-7e6d-4478-8440-74b42ab164aa'))

        const loadingProgramForm = ref(false)
        const showProgramForm = ref(false)
        const programFormSchema = ref(false)
        const programFormDados = ref(false)
        const programFormIdRegistro = ref(null)
        const programFormOnSucces = ref(null)
        const component = ref(null)

        function setData(dados){
            programa.dados = dados
            temp_financial_title.value = JSON.parse(JSON.stringify(dados.titulo_financeiro.registros[0]))
            financial_title.value = dados.titulo_financeiro.registros[0]
            documents.value = dados.sis_documento.registros.sort((a, b) => -(a.id_documento>b.id_documento)||+(a.id_documento<b.id_documento)).map(doc => {
                return {
                    ...doc,
                    loading: false,
                }
            })
            system_comments.value = dados.ocorrencia_titulo_financeiro.registros
            system_comments.value.sort((a, b) => -(a.data_inclusao>b.data_inclusao)||+(a.data_inclusao<b.data_inclusao))
            comments.value = dados.sis_anotacao.registros
            comments.value = comments.value.sort((a, b) => -(a.data_inclusao<b.data_inclusao)||+(a.data_inclusao>b.data_inclusao))
            chatController.value.setMovimentoMessages(comments.value || [])
        }

        const save_financial_title = async ({onSuccess, onError}) => {
            loading_financial_title.value = true
            const body = default_body
            body.dados = {titulo_financeiro: {registros: [temp_financial_title.value]}}
            body.rota = programa.program_schema.rota_backend + '/editar'
            body.id = temp_financial_title.value.id_titulo_financeiro + ''
            await sendFunctionRequest(
                body,
                (res) => {
                    const { dados } = res.data.retorno
                    programa.dados = dados
                    if(onSuccess) onSuccess(dados)
                    setData(dados)
                },
                (err) => {
                    if(onError) onError(err)
                }
            )
            loading_financial_title.value = false
            loadingTitle.value = ''
        }

        const save_by_key = async (key) => {
            loadingTitle.value = 'Salvando'

            switch(key){
                case 'valor_titulo':
                    temp_financial_title.value.valor_titulo = temp_financial_title.value.valor_titulo.replace(/\./g, '').replace(',', '.')
                    break;
                case 'juros_cobranca':
                    temp_financial_title.value.juros_cobranca = temp_financial_title.value.juros_cobranca.replace(/\./g, '').replace(',', '.')
                    break;
                case 'multa_cobranca':
                    temp_financial_title.value.multa_cobranca = temp_financial_title.value.multa_cobranca.replace(/\./g, '').replace(',', '.')
                    break;
                case 'desconto_cobranca':
                    temp_financial_title.value.desconto_cobranca = temp_financial_title.value.desconto_cobranca.replace(/\./g, '').replace(',', '.')
                    break;
            }
            
            save_financial_title({
                onSuccess: (dados) => {
                    temp_financial_title.value[key] = dados.titulo_financeiro.registros[0][key]
                },
                onError: () => {
                    temp_financial_title.value[key] = financial_title.value[key]
                }
            })
        }
        
        const handleSendNewComment = async () => {
            loading_financial_title.value = true
            const body = default_body
            body.rota = programa.program_schema.rota_backend + add_comment_function.value.rota_backend
            body.dados = {
                [programa.program_schema.sis_tabela.nome_tabela]: {
                    registros: [
                        {
                            [props.program_schema.sis_tabela.sis_campo_tabela.find((column) => column.chave_primaria).nome]: route.query.registro_id,
                        },
                    ],
                },
                sis_anotacao: {
                    registros: [
                        {
                            id_titulo_financeiro: financial_title.value.id_titulo_financeiro,
                            anotacao: comment_to_save.value,
                        },
                    ],
                },
            }
            await sendFunctionRequest(
                body,
                (res) => {
                    const { dados } = res.data.retorno
                    programa.dados = dados
                    setData(dados)
                    comment_to_save.value = ''
                },
            )
            loading_financial_title.value = false
        }

        async function showFiles(files, type){
            if(type === 'chamado'){
                currentOriginalFiles2.value = files    
            }else{
                currentOriginalFiles.value = files
            }
            
            const body = default_body
            body.rota = programa.program_schema.rota_backend + function_show_document.value.rota_backend
            body.dados = {
                [programa.program_schema.sis_tabela.nome_tabela]: {
                    registros: [
                        {
                            [props.program_schema.sis_tabela.sis_campo_tabela.find((column) => column.chave_primaria).nome]: route.query.registro_id,
                        },
                    ],
                },
                sis_documento: {
                    registros: files.map(f => {
                        return {
                            id_documento: f.id_documento,
                            id_titulo_financeiro: f.id_titulo_financeiro,
                            link_documento: f.link_documento,
                            nome_documento: f.nome_documento,
                            id_empresa: f.id_empresa
                        }
                    })
                }
            }
            await sendFunctionRequest(
                body,
                (res) => {
                    const {registros} = res.data.retorno.dados.sis_documento
                    if(type === 'chamado'){
                        filesToShow2.value = registros
                        showFileModal2.value = true
                    }else{
                        filesToShow.value = registros
                        showFileModal.value = true
                    }
                }
            )
        }

        const saveFiles = async () => {
            const documentsToUpload = files.value.map(file => {
                return {
                    nome_documento: file.name,
                    arquivo_base64: null,
                    tamanho: file.size,
                    descricao: file.descricao,
                    id_empresa: financial_title.value.id_empresa
                }
            });

            loadingFileUpload.value = true;
            const sfunction = function_add_document.value
            const body = {
                id: route.query.registro_id,
                id_programa: route.params.targetId,
                condicao_adicional: "",
                lista_id_empresa: JSON.parse(localStorage.getItem('companiesGlobalFilter')) ?? [],
                data_trabalho: JSON.parse(localStorage.getItem('dataTrabalho')),
                navegador: false,
                ordenacao: null,
                quantidade_registros: 100,
                pagina: 0,
                dados: {
                        sis_documento: {
                        registros: [...documentsToUpload],
                    },
                    [programa.program_schema.sis_tabela.nome_tabela]: {
                        registros: [
                            {
                                [props.program_schema.sis_tabela.sis_campo_tabela.find((column) => column.chave_primaria).nome]: route.query.registro_id,
                            },
                        ],
                    },
                },
                rota: programa.program_schema.rota_backend + sfunction.rota_backend,
            }

            sendFunctionRequest(body).then(async (res) => {
                if (!res.data?.status) {
                    Swal.fire({
                        icon: "error",
                        text: res.data.mensagem ? res.data.mensagem : res.data.erro,
                        confirmButtonColor: "#34c38f",
                        confirmButtonText: "OK",
                    });
                    loadingFileUpload.value = false;
                } else {
                    showUploadFileModal.value = false;
                    loadingFileUpload.value = false;

                    const { dados } = res.data.retorno
                    programa.dados = dados
                    setData(dados)

                    const documents = res.data.retorno.dados.sis_documento.registros
                    const documentsToUploadAzure = []
                    files.value.forEach(file => {
                        const filteredDocuments = documents
                            .filter(doc => doc.nome_documento == file.name && doc.tamanho == file.size)
                            .sort((a, b) => new Date(b.data_inclusao) - new Date(a.data_inclusao))
                        const document = filteredDocuments[0] ?? null
                        if (document) {
                            document.file = file
                            documentsToUploadAzure.push(document)
                        }
                    })

                    const azurehelper = new AzureHelper()
                    await azurehelper.uploadDocuments(documentsToUploadAzure)

                    showFiles(dados.sis_documento.registros)
                }
            });
        }

        const remove_document = async (document) => {
            Swal.fire({
                text: `Tem certeza que deseja remover o documento "${document.nome_documento}"?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    document.loading = true
                    const body = default_body
                    body.rota = programa.program_schema.rota_backend + function_remove_document.value.rota_backend
                    body.dados = {
                        [programa.program_schema.sis_tabela.nome_tabela]: {
                            registros: [
                                {
                                    [props.program_schema.sis_tabela.sis_campo_tabela.find((column) => column.chave_primaria).nome]: route.query.registro_id,
                                },
                            ],
                        },
                        sis_documento: {
                            registros: [
                                {
                                    id_documento: document.id_documento,
                                    id_titulo_financeiro: document.id_titulo_financeiro,
                                    link_documento: document.link_documento,
                                    nome_documento: document.nome_documento,
                                    id_empresa: document.id_empresa
                                }
                            ]
                        }
                    }
                    await sendFunctionRequest(
                        body,
                        (res) => {
                            const { dados } = res.data.retorno
                            programa.dados = dados
                            setData(dados)
                        }
                    )
                    document.loading = false      
                }
            })
        }

        const downloadFile = async (data) => {
            const documents = data.documents
            const single = data.single

            const body = default_body
            body.rota = programa.program_schema.rota_backend + function_download_document.value.rota_backend
            body.dados = {
                [programa.program_schema.sis_tabela.nome_tabela]: {
                    registros: [
                        {
                            [props.program_schema.sis_tabela.sis_campo_tabela.find((column) => column.chave_primaria).nome]: route.query.registro_id,
                        },
                    ],
                },
                sis_documento: {
                    registros: [
                        ...documents
                    ]
                }
            }
            await sendFunctionRequest(body,
                (res) => {
                    downloadDocument(`#${financial_title.value.id_titulo_financeiro}${single ? ' - ' + documents[0].nome_documento : ''}` + '.zip', res.data.retorno)
                }
            )
        }

        const setShowUploadFileModal = () => {
            files.value = []
            showUploadFileModal.value = true
        }

        const copyToClipBoard = (text) => {
            navigator.clipboard.writeText(text)
            document.getElementById('copy-message')?.classList.add('success-copy-message');
            setTimeout(() => {
                document.getElementById('copy-message')?.classList.remove('success-copy-message');
            }, 2000)
        }

        const goToNextRegister = async () => {
            if(store.state.frameworkNavegador.currentPageData.length > 1){
                const currentIndexKey = store.state.frameworkNavegador.currentPageData.findIndex(data => data.id_titulo_financeiro == route.query.registro_id)
                if(currentIndexKey < store.state.frameworkNavegador.currentPageData.length - 1){
                    const next = store.state.frameworkNavegador.currentPageData[currentIndexKey + 1]
                    const query = Object.assign({}, route.query);
                    query.registro_id = next.id_titulo_financeiro;
                    router.push({ query });
                }
            }
        }

        const goToPreviousRegister = async () => {
            if(store.state.frameworkNavegador.currentPageData.length > 1){
                const currentIndexKey = store.state.frameworkNavegador.currentPageData.findIndex(data => data.id_titulo_financeiro == route.query.registro_id)
                if(currentIndexKey > 0){
                    const previous = store.state.frameworkNavegador.currentPageData[currentIndexKey - 1]
                    const query = Object.assign({}, route.query);
                    query.registro_id = previous.id_titulo_financeiro;
                    router.push({ query });
                }
            }
        }

        const hasNextRegister = computed(() => {
            if(store.state.frameworkNavegador.currentPageData.length > 1){
                const currentIndexKey = store.state.frameworkNavegador.currentPageData.findIndex(data => data.id_titulo_financeiro == route.query.registro_id)
                return currentIndexKey < store.state.frameworkNavegador.currentPageData.length - 1
            }
            return false
        })

        const hasPreviousRegister = computed(() => {
            if(store.state.frameworkNavegador.currentPageData.length > 1){
                const currentIndexKey = store.state.frameworkNavegador.currentPageData.findIndex(data => data.id_titulo_financeiro == route.query.registro_id)
                return currentIndexKey > 0
            }
            return false
        })

        const getCurrentPagination = computed(() => {
            if(store.state.frameworkNavegador.currentPageData.length > 1){
                const currentIndexKey = store.state.frameworkNavegador.currentPageData.findIndex(data => data.id_titulo_financeiro == route.query.registro_id)
                return `${currentIndexKey + 1} / ${store.state.frameworkNavegador.currentPageData.length}`
            }
            return ''
        })

        function onKeyUp(event) {
            let p_function = null
            if (event.ctrlKey &&  event.shiftKey && event.keyCode === 49) {//1
                p_function = programa.program_schema.sis_funcionalidade.find(sf => sf.id_funcionalidade == '6d22f883-df90-4d94-9077-4d0ce3ec516a')
            }
            if (event.ctrlKey &&  event.shiftKey && event.keyCode === 50) {//2
                p_function = programa.program_schema.sis_funcionalidade.find(sf => sf.id_funcionalidade == '84a4e6a3-967d-44ec-92bf-517002fb6c4a')
            }
            if (event.ctrlKey &&  event.shiftKey && event.keyCode === 51) {//3
                p_function = programa.program_schema.sis_funcionalidade.find(sf => sf.id_funcionalidade == '75c360a4-80b8-481a-ab91-41e9d303c409')
            }
            if (event.ctrlKey &&  event.shiftKey && event.keyCode === 52) {//4
                p_function = programa.program_schema.sis_funcionalidade.find(sf => sf.id_funcionalidade == '1de8c5d7-fb67-41d8-b990-eb76ccc6c115')
            }
            if (event.ctrlKey &&  event.shiftKey && event.keyCode === 53) {//5
                p_function = programa.program_schema.sis_funcionalidade.find(sf => sf.id_funcionalidade == 'c8d1240f-680d-49c2-bf18-64d53ef47430')
            }
            if (event.ctrlKey &&  event.shiftKey && event.keyCode === 54) {//6
                p_function = programa.program_schema.sis_funcionalidade.find(sf => sf.id_funcionalidade == 'f8768f85-670f-4d87-a657-3b62f3f2243f')
            }
            if (event.ctrlKey &&  event.shiftKey && event.keyCode === 13) {//Enter
                p_function = programa.program_schema.sis_funcionalidade.find(sf => sf.id_funcionalidade == 'bc46e597-ada3-45db-b1c9-95726e764b9c')
            }
            if (event.ctrlKey &&  event.shiftKey && event.keyCode === 193) {//?
                p_function = programa.program_schema.sis_funcionalidade.find(sf => sf.id_funcionalidade == '636b7c40-7ef4-42d4-b064-f0e2d7eb7ee8')
            }
            if (event.ctrlKey &&  event.shiftKey && event.keyCode === 55) {//7
                console.log('teste')
                p_function = programa.program_schema.sis_funcionalidade.find(sf => sf.id_funcionalidade == 'e8705089-6fd4-47d5-bbcb-58b090e8c8e7')
            }
            if (event.ctrlKey &&  event.shiftKey && event.keyCode === 56) {//8
                console.log('teste')
                p_function = programa.program_schema.sis_funcionalidade.find(sf => sf.id_funcionalidade == 'ffafbfeb-a936-4087-9d76-ad4b3c18dd57')
            }


            if (event.ctrlKey &&  event.shiftKey && event.keyCode === 57) {//9
                document.getElementById('downloadFilesButton').click()
            }
            if (event.ctrlKey &&  event.shiftKey && event.keyCode === 39) {//seta direita
                goToNextRegister()
            }
            if (event.ctrlKey &&  event.shiftKey && event.keyCode === 37) {//seta esquerda
                goToPreviousRegister()
            }
            if(event.key === 'Escape' && showProgramForm.value){
                showProgramForm.value = false
            }

            if(p_function){
                event.preventDefault()
                event.stopPropagation()
                document.getElementById('f_button_' + p_function.id_funcionalidade).click()
            }
        }

        const showSupplierList = async () => {
            const res = await getValueListById('5a276875-0818-4525-b3a8-f47e7450f76f', '')
            store.commit('massActionsModals/setProperty', [
                {
                    key: 'showDynamicFilterValueListModal',
                    value: true
                },
                {
                    key: 'title',
                    value: ''
                },
                {
                    key: 'sis_lista_valor',
                    value: res.sis_lista_valor
                },
                {
                    key: 'onSave',
                    value: (ids_object) => {
                        temp_financial_title.value.id_credor_devedor = ids_object.pessoa.registros[0].id_pessoa
                        save_by_key('id_credor_devedor')
                    }
                }
            ])
        }

        const showFormModal = async (id_programa, id_registro = null) => {
            loadingProgramForm.value = true
            showProgramForm.value = true
            programFormSchema.value = null
            programFormDados.value = null
            programFormIdRegistro.value = id_registro
            programFormOnSucces.value = () => {
                showProgramForm.value = false
            }

            const body = {
                id_programa: id_programa,
                id: id_registro,
                condicao_adicional: "",
                lista_id_empresa: JSON.parse(localStorage.getItem('companiesGlobalFilter')) ?? [],
                data_trabalho: JSON.parse(localStorage.getItem('dataTrabalho')),
                navegador: false,
                ordenacao: null,
                quantidade_registros: id_registro ? 1 : 0,
                pagina: 0,
                rota: 'obtem_dados_programa_usuario'
            }
            await ApiService({
                method: 'POST',
                url: `/program`,
                data: body,
                headers: {'authorization': JSON.parse(localStorage.getItem('user')).token}
            }).then(res => {
                const data = res.data;
                if(!data.status){
                    Swal.fire({
                        icon: "error",
                        text: data.mensagem ? data.mensagem : data.erro,
                        confirmButtonColor: "#34c38f",  
                        confirmButtonText: "OK",
                    });
                    return false;
                }
                programFormDados.value = res.data.retorno.dados
                const fps = res.data.retorno.sis_programa
                fps.sis_tabela.sis_campo_tabela.sort((a,b) => a.ordem_exibicao - b.ordem_exibicao)
                fps.sis_tabela.sis_campo_tabela.map(col => {
                    if(col.id_tabela_detalhe){
                        col.dados_tabela_detalhe.sis_tabela.sis_campo_tabela.sort((a,b) => a.ordem_exibicao - b.ordem_exibicao) 
                    }
                })
                fps.sis_tabela = putPrimaryKeyInFirstIndex(fps.sis_tabela);
                programFormSchema.value = fps
                
                const asyncComponent = defineAsyncComponent(() =>
                    import(`@/views/formularios${programFormSchema.value.formulario_edicao}`)
                );
                component.value = markRaw(asyncComponent);
            }).catch(error => {
                Swal.fire({
                    icon: "error",
                    text: error.response?.data?.mensagem ?? error.message,
                    confirmButtonColor: "#34c38f",  
                    confirmButtonText: "OK",
                });
                return false;
            }).finally(() => {
                loadingProgramForm.value = false
            })
        }

        const callFunctionAction = (p_function) => {
            callFunction(
                p_function,
                props.registro_id,
                programa.program_schema,
                programa.dados.titulo_financeiro?.registros[0],
                (dados) => {
                    setData(dados)
                },
                () => {
                    loadingTitle.value = p_function.denominacao
                    loading_financial_title.value = true
                },
                () => loading_financial_title.value = false, 
                (res) => {
                    if(p_function.id_funcionalidade !== 'bc46e597-ada3-45db-b1c9-95726e764b9c'){
                        setData(res.data.retorno.dados)
                    }
                },
                () => goToNextRegister()
            )
        }

        onMounted(() => {
            document.addEventListener('keyup', onKeyUp)
            if(props.dados.sis_documento.registros.length) {
                showFiles(props.dados.sis_documento.registros)
            }

            setDynamicGrid()
            setInputText()
        })

        function setInputText() {
            if (![1446489765, 1546734496].includes(financial_title.value.id_tipo_cobranca)) {
                showInputText.value = false
                return
            }

            if(financial_title.value.id_tipo_cobranca === 1546734496 && !financial_title.value.qr_code_pix){
                showInputText.value = true
            }else if(financial_title.value.id_tipo_cobranca === 1446489765 && !financial_title.value.codigo_barras_boleto){
                showInputText.value = true
            }
        }

        function setDynamicGrid(){
            if(![3, 1474606972, 256796645].includes(financial_title.value.id_tipo_cobranca)){
                showDynamicGridTable.value = false
                return
            }
            
            DYNAMIC_VALUE_LIST_TABLE_FUNCTIONS.value = null;
            const grid = document.getElementById(dynamicGridTableId)
            if(grid){
                grid.innerHTML = "";
            }

            (async () => {
                if(financial_title.value.id_tipo_cobranca === 3 && !financial_title.value.boleto_emitido){
                    showDynamicGridTable.value = true
                    loadingDynamicTable.value = true
                    let list_id = "5e74467f-f4d3-42ca-9097-b5d39bba45f3";
                    let condition = `vw_debito_direto_autorizado.valor_titulo = ${financial_title.value.valor_titulo} AND vw_debito_direto_autorizado.id_empresa = ${financial_title.value.id_empresa} AND LEFT(TRIM(REGEXP_REPLACE(vw_debito_direto_autorizado.cnpj_cpf_credor_devedor, '\\D', '', 'g')), 8) = LEFT(TRIM(REGEXP_REPLACE((SELECT p.cnpj_cpf FROM pessoa p WHERE p.id_pessoa = ${financial_title.value.id_credor_devedor}), '\\D', '', 'g')), 8)`;
                    const res = await getValueListById(list_id, condition)
                    const sis_lista_valor = res.sis_lista_valor

                    createTable(
                        sis_lista_valor.sis_opcao_lista_valor ?? [],
                        sis_lista_valor,
                        (targetId) => configBeneficiary('bank_slip', targetId)
                    )
                    loadingDynamicTable.value = false
                }else if([1474606972, 256796645].includes(financial_title.value.id_tipo_cobranca) && !financial_title.value.id_banco_pagamento){
                    showDynamicGridTable.value = true
                    loadingDynamicTable.value = true
                    let list_id = "371ee293-9b2a-405a-a027-ad5b83676e24";
                    let condition = `id_pessoa = ${financial_title.value.id_credor_devedor}`;
                    const res = await getValueListById(list_id, condition)
                    const sis_lista_valor = res.sis_lista_valor

                    createTable(
                        sis_lista_valor.sis_opcao_lista_valor ?? [],
                        sis_lista_valor,
                        (targetId) => configBeneficiary(financial_title.value.id_tipo_cobranca === 1474606972 ? 'pix' : 'ted/doc', targetId)
                    )
                    loadingDynamicTable.value = false
                }
            })()
        }

        async function configBeneficiary(type, id){
            let entityName = ''
            let entityPrimaryKeyName = ''
            let __function = null

            if(type === 'bank_slip'){
                entityName = 'vw_debito_direto_autorizado'
                entityPrimaryKeyName = 'id_debito_direto_autorizado'
                __function = function_bank_slip_config.value
            }
            if(type === 'pix'){
                entityName = 'vw_conta_financeira_pessoa'
                entityPrimaryKeyName = 'id_conta_financeira_pessoa'
                __function = function_pix_config.value
            }
            if(type === 'ted/doc'){
                entityName = 'vw_conta_financeira_pessoa'
                entityPrimaryKeyName = 'id_conta_financeira_pessoa'
                __function = function_ted_doc_config.value
            }
            const asyncJob = {
                id: uuidv4(),
                title: null,
                message: null,
                status: "running",
            };
            asyncJob.title = programa.program_schema.denominacao + " > " + __function.denominacao;
            asyncJob.message = "Operação em andamento...";
            asyncJob.status = "running";
            store.commit("asyncJobs/addJob", asyncJob);

            const body = default_body
            body.rota = programa.program_schema.rota_backend + __function.rota_backend
            body.dados = {
                [programa.program_schema.sis_tabela.nome_tabela]: {
                    registros: [{[primaryKeyName]: route.query.registro_id}],
                },
                [entityName]: {
                    registros: [{[entityPrimaryKeyName]: id}],
                },
            }
            
            sendFunctionRequest(
                body,
                (res) => {
                    asyncJob.message = "Operação concluída!";
                    asyncJob.status = "done";
                    store.commit("asyncJobs/updateJob", { ...asyncJob });

                    Swal.fire({
                        icon: "success",
                        text: `Salvo!`,
                        confirmButtonText: "OK",
                    });

                    setData(res.data.retorno.dados)
                    showDynamicGridTable.value = false
                },
                (error) => {
                    asyncJob.message = error;
                    asyncJob.status = "failed";
                    store.commit("asyncJobs/updateJob", { ...asyncJob });
                }
            );
        }

        function createTable(registros, sis_lista_valor, onClick){
            const valueListTitles = getTitlesFromListValue(sis_lista_valor)
            const columns = valueListTitles?.map((title, index) => {
                return {
                    titulo: title,
                    nome: 'codigo'+[index+1],
                }
            }) || []
            columns.push({
                titulo: 'Denominação',
                nome: 'titulo'
            })

            document.getElementById(dynamicGridTableId).innerHTML = "";
            DYNAMIC_VALUE_LIST_TABLE_FUNCTIONS.value = useValueListTable(
                dynamicGridTable,
                dynamicGridTableId,
                columns,
                () => {
                    // selected_items.value = [...value];
                },
                (targetId) => {
                    onClick(targetId)
                }
            );
            DYNAMIC_VALUE_LIST_TABLE_FUNCTIONS.value.setTableColumns([])
            DYNAMIC_VALUE_LIST_TABLE_FUNCTIONS.value.updateConfig(registros);
        }
      
        const getDocumentsByChamadoId = (id_chamado) => {
            return programa.dados.sis_documento_titulo_financeiro_chamado.registros.filter(doc => doc.id_chamado === id_chamado)
        }

        function saveItems() {
            saveLoading.value = true

            let objeto = {};
            let rota_backend = null

            if (financial_title.value.id_tipo_cobranca === 1546734496) {
                objeto = {
                    id_titulo_financeiro: financial_title.value.id_titulo_financeiro,
                    qr_code_pix: stringToBeSaved.value
                };
                rota_backend = function_qrcode_config.value.rota_backend
            } else if (financial_title.value.id_tipo_cobranca === 1446489765) {
                objeto = {
                    id_titulo_financeiro: financial_title.value.id_titulo_financeiro,
                    codigo_barras_boleto: stringToBeSaved.value
                };
                rota_backend = function_bank_slip_config.value.rota_backend
            }

            const payload = {
                solicita_tfa: false,
                id_programa: route.params.targetId,
                condicao_adicional: '',
                lista_id_empresa: JSON.parse(localStorage.getItem('companiesGlobalFilter')) ?? [],
                data_trabalho: JSON.parse(localStorage.getItem('dataTrabalho')),
                navegador: false,
                ordenacao: null,
                quantidade_registros: 0,
                pagina: 0,
                rota: programa.program_schema.rota_backend + rota_backend,
                dados: {
                    [programa.program_schema.sis_tabela.nome_tabela]: {
                        registros: [
                            objeto
                        ],
                    }
                }
            }
            sendFunctionRequest(payload)
            .then(async (res) => {
                if (res.data.status) {
                    Swal.fire({
                        icon: "success",
                        text: `Salvo!`,
                        confirmButtonText: "OK",
                    });
                    showInputText.value = false
                }
                saveLoading.value = false
            })
        }
        const deleteOccurrence = async (id) => {
            const asyncJob = {
                id: uuidv4(),
                title: null,
                message: null,
                status: "running",
            };
            asyncJob.title = programa.program_schema.denominacao + " > " + function_delete_occurrence.value.denominacao;
            asyncJob.message = "Operação em andamento...";
            asyncJob.status = "running";
            store.commit("asyncJobs/addJob", asyncJob);

            const body = default_body
            body.rota = programa.program_schema.rota_backend + function_delete_occurrence.value.rota_backend
            body.dados = {
                [programa.program_schema.sis_tabela.nome_tabela]: {
                    registros: [{[primaryKeyName]: route.query.registro_id}],
                },
                ocorrencia_titulo_financeiro: {
                    registros: [{id_ocorrencia_titulo_financeiro: id}],
                },
            }
            
            sendFunctionRequest(
                body,
                (res) => {
                    asyncJob.message = "Operação concluída!";
                    asyncJob.status = "done";
                    store.commit("asyncJobs/updateJob", { ...asyncJob });

                    Swal.fire({
                        icon: "success",
                        text: `Salvo!`,
                        confirmButtonText: "OK",
                    });

                    setData(res.data.retorno.dados)
                },
                (error) => {
                    asyncJob.message = error;
                    asyncJob.status = "failed";
                    store.commit("asyncJobs/updateJob", { ...asyncJob });
                }
            );
        }

        onUnmounted(() => document.removeEventListener('keyup', onKeyUp))
        watch(() => financial_title.value, (tf) => {
            if(tf.id_tipo_cobranca === 3 && !tf.boleto_emitido || [1474606972, 256796645].includes(tf.id_tipo_cobranca) && !tf.id_banco_pagamento || tf.id_tipo_cobranca === 1546734496 && !tf.qr_code_pix || tf.id_tipo_cobranca === 1446489765 && !tf.codigo_barras_boleto){
                setDynamicGrid()
                setInputText()
            }else{
                showDynamicGridTable.value = false
                showInputText.value = false
            }
        })
        setData(props.dados)

        return {
            deleteOccurrence,
            getDocumentsByChamadoId,
            setInputText,
            showInputText,
            stringToBeSaved,
            saveLoading,
            saveItems,
            showFormModal,
            goToNextRegister,
            goToPreviousRegister,
            hasNextRegister,
            hasPreviousRegister,
            getCurrentPagination,
            loadingTitle,
            showQrCode,
            copyToClipBoard,
            callFunction,
            save_by_key,
            handleSubmitMessage,
            handleDeleteMessage,
            movimentoMessages,
            temp_financial_title,
            function_bank_slip_config,
            function_pix_config,
            function_ted_doc_config,
            function_tax_config,
            function_qrcode_config,
            function_generate_receipt,
            function_approve_payment,
            function_repprove_payment,
            system_comments,
            files,
            setShowUploadFileModal,
            documents,
            moment,
            currentOriginalFiles,
            currentOriginalFiles2,
            downloadFile,
            remove_document,
            saveFiles,
            loadingFileUpload,
            showUploadFileModal,
            showFileModal,
            showFileModal2,
            filesToShow,
            filesToShow2,
            showFiles,
            function_show_document,
            function_download_document,
            function_remove_document,
            handleSendNewComment,
            comment_to_save,
            current_user,
            comments,
            financial_title,
            save_financial_title,
            loading_financial_title,
            programa,
            jurisdictions,
            enterprises,
            natures,
            chargeTypes,
            showSupplierList,
            component,
            loadingProgramForm,
            programFormSchema,
            showProgramForm,
            programFormDados,
            programFormOnSucces,
            programFormIdRegistro,
            callFunctionAction,
            dynamicGridTableId,
            loadingDynamicTable,
            showDynamicGridTable,
            function_update_bank_slip,
            function_delete_occurrence,
            routerBack: () => {
                store.commit('frameworkNavegador/setBeingReturned', true)
                const query = Object.assign({}, route.query)
                delete query.registro_id
                delete query.tipo
                router.push({ query });
            },
            dateConfig: {
                altFormat: "d/m/Y",
                altInput: true,
                dateFormat: "Y-m-d",
                locale: PortugueseLocale,
                allowInput: true,
            },
            format_numeric: function (newValue) {
                const defaultFormat = 'N2'
                const brazilCurrencyFormat = /(\d)(?=(\d{3})+(?!\d))/g
                const zeroInString = '0'
                const oneValue = 1
                const [, precision] = defaultFormat.split('N')
                const value = typeof newValue === 'number' ? Number(newValue).toFixed(precision) : newValue
                const onlyNumbers = value.toString().replace(/[.,]/g, '')

                const numberToDivision = String(oneValue).padEnd(Number(precision) + oneValue, zeroInString)
                const valueWithDecimal = (Number(onlyNumbers).toFixed(precision) / Number(numberToDivision)).toFixed(precision)
                const valueWithComma = valueWithDecimal.toString().replace('.', ',')
                const valueFormatted = valueWithComma.replace(brazilCurrencyFormat, '$1.')

                return valueFormatted
            },
        }
    },
    components: {
        QrcodeVue,
        ChatBox,
        DocumentsVisualizationModal,
        uploadFile,
        FunctionButtons,
        SimpleLoadigPage,
        ContasPagarContainer,
        flatPickr
    }
}
</script>

<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="card mt-n4 mx-n4 mb-n5">
                <div class="bg-soft-warning">
                    <div class="card-body pb-4 mb-5">
                        <div class="row">
                            <div class="col-md mx-3">
                                <div class="row align-items-center">
                                    <div class="col-md">
                                        <h4 class="fw-semibold w-fit-content" v-b-tooltip.hover.top="'ID do título financeiro'">#{{ financial_title.id_titulo_financeiro }}</h4>
                                        <div class="hstack gap-3 flex-wrap">
                                            <div class="fw-semibold" v-b-tooltip.hover.top="'Aprovação'">Aprovação: <span class="fw-medium">{{financial_title.qtde_percentual_aprovacao}}</span></div>
                                            <div class="vr"></div>
                                            <div class="fw-semibold" v-b-tooltip.hover.top="'Data de Lançamento'">Data de Lançamento: <span class="fw-medium">{{moment(financial_title.data_lancamento).format("DD/MM/YYYY")}}</span></div>
                                            <div class="vr"></div>
                                            <div class="fw-semibold" v-b-tooltip.hover.top="'Usuário de Lançamento'">Usuário de Lançamento: <span class="fw-medium">{{financial_title.usuario_lancamento}}</span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="mb-2 d-flex align-items-center" v-if="this.$store.state.frameworkNavegador.currentPageData?.length > 1">
            <a v-if="hasPreviousRegister" class="avatar-xs me-5" href="javascript: void(0);" v-b-tooltip.hover.right="'(Ctrl + Shift + Seta Esquerda) Anterior'" @click="goToPreviousRegister">
                <div
                    style="width:30px;"
                    class="avatar-title fs-16 rounded-circle bg-light border-dashed border text-primary">
                    <i  class="fs-20 align-middle ri-arrow-left-s-line"></i>
                </div>
            </a>
            <div class="border border-dashed border-bottom-0 border-start-0 border-end-0" style="width:inherit;"></div>
            <div class="text-muted mx-5" style="white-space:nowrap;">
                <span class="fw-semibold">{{getCurrentPagination}}</span> de <span class="fw-semibold">{{this.$store.state.frameworkNavegador.currentDataTotal}}</span> resultados
            </div>
            <div class="border border-dashed border-bottom-0 border-start-0 border-end-0 w-100" style="width:inherit;"></div>

            <a v-if="hasNextRegister" class="avatar-xs ms-5" style="width:30px;" href="javascript: void(0);" v-b-tooltip.hover.left="'(Ctrl + Shift + Seta Direita) Próximo'" @click="goToNextRegister">
                <div
                    style="width:30px;"
                    class="avatar-title fs-16 rounded-circle bg-light border-dashed border text-primary">
                    <i  class="fs-20 align-middle ri-arrow-right-s-line"></i>
                </div>
            </a>
        </div>

        <div class="col-12 col-lg-5">
            <div class="card">
                <div class="card-header d-flex align-items-center gap-3 py-1">
                    <FunctionButtons
                        :registro_id="this.$props.registro_id"
                        :functions="programa.program_schema.sis_funcionalidade"
                        :id_tabela="programa.program_schema.sis_tabela.id_tabela"
                        :callFunction="callFunctionAction"
                    />
                    <a @click="routerBack" class="ms-auto btn btn-light border shadow-sm" title="Voltar">
                        <i class="ri-arrow-go-back-fill align-bottom me-1"></i> Voltar
                    </a>
                </div>
                <div class="card-body">
                    <div class="table-responsive table-card overflow-hidden">
                        <table class="table mb-0">
                            <tbody>
                                <tr>
                                    <td colspan="3" class="p-0 border-start border-end" style="height: 1px;">
                                        <div class="d-flex h-100 position-relative">
                                            <select class="form-control border-0 py-1 rounded-0 pt-3 fw-semibold" style="cursor: pointer;" v-model="temp_financial_title.id_empresa" @change="save_by_key('id_empresa')">
                                                <option v-for="enterprise in enterprises" :value="enterprise.id" :key="enterprise.id">{{enterprise.titulo}}</option>
                                            </select>
                                            <p class="mb-0 fs-10 position-absolute" style="top:4px;left:15px;">Empresa
                                                <i class="mdi text-warning mdi-pencil fs-10"></i>
                                            </p>
                                        </div>
                                    </td>
                                    <td colspan="2" class="p-0 border-start border-end" style="height: 1px;">
                                        <div class="d-flex h-100 position-relative">
                                            <select class="form-control border-0 py-1 rounded-0 pt-3 fw-semibold" style="cursor: pointer;" v-model="temp_financial_title.natureza_titulo" @change="save_by_key('natureza_titulo')">
                                                <option v-for="nature in natures" :value="nature.id" :key="nature.id">{{nature.titulo}}</option>
                                            </select>
                                            <p class="mb-0 fs-10 position-absolute" style="top:4px;left:15px;">Natureza
                                                <i class="mdi text-warning mdi-pencil fs-10"></i>
                                            </p>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="5" class="border-start border-end py-1">
                                        <div class="d-flex titulo_financeiro position-relative">
                                            <p class="mb-0 fs-10 position-absolute" style="top:4px;left:0px;">Fornecedor
                                                <i class="mdi text-warning mdi-pencil fs-10"></i>
                                            </p>
                                            <div class="flex-1">
                                                <p @click="showSupplierList" class="fw-semibold mb-0 pt-3" style="cursor:pointer;">{{temp_financial_title.nome_credor_devedor}}</p>
                                            </div>
                                            <button
                                                @click="showSupplierList"
                                                class="btn btn-icon btn-light text-primary rounded-0 rounded-start" type="button"
                                                style="height: 38px;"
                                                v-b-tooltip.hover.top="'Pesquisar'"
                                            >
                                                <i class="ri-search-eye-line fs-16"></i>
                                            </button>
                                            <div class="dropdown align-self-start message-box-drop rounded-0 position-static">
                                                <a class="dropdown-toggle btn btn-icon btn-light border-0 border-start rounded-0 rounded-end" href="#" role="button" data-bs-toggle="dropdown" data-bs-popper-config='{"strategy":"fixed"}' aria-haspopup="true" aria-expanded="false" style="height:38px">
                                                    <i class="ri-more-2-fill" />
                                                </a>
                                                <div class="dropdown-menu">
                                                    <div class="list-group-item-action">
                                                        <a class="dropdown-item disabled" role="button">
                                                            <i class="link-success mdi mdi-plus-circle align-middle me-2" />
                                                            Novo
                                                        </a>
                                                    </div>
                                                    <div class="list-group-item-action" style="pointer">
                                                        <a class="dropdown-item" role="button" @click="showFormModal('971fd89a-9507-4fa2-b1f4-2b15dc643efd', temp_financial_title.id_credor_devedor + '')">
                                                            <i class="link-warning mdi mdi-pencil align-middle me-2" />
                                                            Editar
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="py-1 border-start border-end">
                                        <p class="mb-0 fs-10">Data de Aprovação</p>
                                        <p class="fw-semibold mb-0">{{ financial_title.data_aprovacao_pagamento ? moment(financial_title.data_aprovacao_pagamento).format("DD/MM/YYYY") : '-'}}</p>
                                    </td>
                                    <td class="py-1 border-start border-end" colspan="1">
                                        <p class="mb-0 fs-10">Aprovador</p>
                                        <p class="fw-semibold mb-0">{{financial_title.usuario_aprovacao_pagamento ?? '-'}}</p>
                                    </td>
                                    <td colspan="3" rowspan="1" class="p-0 border-start border-end" style="height: 1px;">
                                        <div class="d-flex h-100 position-relative">
                                            <select class="text-wrap form-control border-0 py-1 rounded-0 pt-3 fw-semibold" style="cursor: pointer;" v-model="temp_financial_title.id_competencia" @change="save_by_key('id_competencia')">
                                                <option v-for="jurisdiction in jurisdictions" :value="jurisdiction.id" :key="jurisdiction.id">{{jurisdiction.titulo}}</option>
                                            </select>
                                            <p class="mb-0 fs-10 position-absolute" style="top:4px;left:15px;">Setor
                                                <i class="mdi text-warning mdi-pencil fs-10"></i>
                                            </p>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0" style="height: 1px;">
                                        <div class="d-flex h-100 position-relative"> 
                                            <flat-pickr
                                                v-model="temp_financial_title.data_vencimento"
                                                :config="{...dateConfig}"
                                                class="form-control flatpickr-input border-0 py-1 rounded-0 pt-3 fw-semibold"
                                            ></flat-pickr>
                                            <button
                                                v-if="temp_financial_title.data_vencimento !== financial_title.data_vencimento"
                                                @click="save_by_key('data_vencimento')"
                                                style="width:70px;"
                                                class="h-100 btn btn-icon btn-success rounded-0"
                                                v-b-tooltip.hover.top="'Salvar'"
                                            >
                                                <i class="link-light ri-save-2-fill align-bottom"></i>
                                            </button>
                                            <p class="mb-0 fs-10 position-absolute" style="top:4px;left:15px;">Data de Vencimento
                                                <i class="mdi text-warning mdi-pencil fs-10"></i>
                                            </p>
                                        </div>
                                    </td>
                                    <td class="py-1 border-start border-end">
                                        <p class="mb-0 fs-10">Parcela</p>
                                        <p class="fw-semibold mb-0">{{financial_title.numero_parcela}} de {{financial_title.qtd_parcela}}</p>
                                    </td>                                    
                                    <td class="py-1 border-start border" colspan="3">
                                        <p class="mb-0 fs-10">Situação</p>
                                        <p class="fw-semibold mb-0">{{financial_title.titulo_situacao}}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="py-1 border-start border-end">
                                        <p class="mb-0 fs-10">Data de Pagamento</p>
                                        <p class="fw-semibold mb-0">{{financial_title.data_pagamento ? moment(financial_title.data_pagamento).format("DD/MM/YYYY") : ''}}</p>
                                    </td>
                                    <td class="p-0" style="height: 1px;">
                                        <div class="d-flex h-100 position-relative">
                                            <select class="text-wrap form-control border-0 py-1 rounded-0 pt-3 fw-semibold" style="cursor: pointer;" v-model="temp_financial_title.id_tipo_cobranca" @change="save_by_key('id_tipo_cobranca')">
                                                <option v-for="chargeType in chargeTypes" :value="chargeType.id" :key="chargeType.id">{{chargeType.titulo}}</option>
                                            </select>
                                            <p class="mb-0 fs-10 position-absolute" style="top:4px;left:15px;">Tipo de Cobrança
                                                <i class="mdi text-warning mdi-pencil fs-10"></i>
                                            </p>
                                        </div>
                                    </td>
                                    <td class="py-1 border-start border-end" colspan=3>
                                        <p class="mb-0 fs-10">Conta de Liquidação</p>
                                        <p class="fw-semibold mb-0">{{financial_title.denominacao_conta_liquidacao}}</p>
                                    </td>
                                </tr>
                                <tr>                                    
                                    <td class="p-0 border" style="height: 1px;">
                                        <div class="d-flex h-100 position-relative"> 
                                            <input 
                                                @keydown.enter.prevent="save_by_key('valor_titulo')" 
                                                class="form-control border-0 py-1 rounded-0 pt-3 fw-semibold" 
                                                :value="format_numeric(temp_financial_title.valor_titulo)"
                                                @input="temp_financial_title.valor_titulo = format_numeric($event.target.value)" 
                                                type="text"
                                            />
                                            <button
                                                v-if="temp_financial_title.valor_titulo !== financial_title.valor_titulo && temp_financial_title.valor_titulo !== ''"
                                                @click="save_by_key('valor_titulo')"
                                                style="width:70px;"
                                                class="h-100 btn btn-icon btn-success rounded-0"
                                                v-b-tooltip.hover.top="'Salvar'"
                                            >
                                                <i class="link-light ri-save-2-fill align-bottom"></i>
                                            </button>
                                            <p class="mb-0 fs-10 position-absolute" style="top:4px;left:9px;">Valor
                                                <i class="mdi text-warning mdi-pencil fs-10"></i>
                                            </p>
                                        </div>
                                    </td>
                                    <td class="p-0 border" style="height: 1px;">
                                        <div class="d-flex h-100 position-relative"> 
                                            <input 
                                                @keydown.enter.prevent="save_by_key('juros_cobranca')" 
                                                class="form-control border-0 py-1 rounded-0 pt-3 fw-semibold" 
                                                :value="format_numeric(temp_financial_title.juros_cobranca)"
                                                @input="temp_financial_title.juros_cobranca = format_numeric($event.target.value)" 
                                                type="text"
                                            />
                                            <button
                                                v-if="temp_financial_title.juros_cobranca !== financial_title.juros_cobranca && temp_financial_title.juros_cobranca !== ''"
                                                @click="save_by_key('juros_cobranca')"
                                                style="width:70px;"
                                                class="h-100 btn btn-icon btn-success rounded-0"
                                                v-b-tooltip.hover.top="'Salvar'"
                                            >
                                                <i class="link-light ri-save-2-fill align-bottom"></i>
                                            </button>
                                            <p class="mb-0 fs-10 position-absolute" style="top:4px;left:9px;">Juros
                                                <i class="mdi text-warning mdi-pencil fs-10"></i>
                                            </p>
                                        </div>
                                    </td>
                                    <td class="p-0 border" style="height: 1px;">
                                        <div class="d-flex h-100 position-relative"> 
                                            <input 
                                                @keydown.enter.prevent="save_by_key('multa_cobranca')" 
                                                class="form-control border-0 py-1 rounded-0 pt-3 fw-semibold" 
                                                :value="format_numeric(temp_financial_title.multa_cobranca)"
                                                @input="temp_financial_title.multa_cobranca = format_numeric($event.target.value)" 
                                                type="text"
                                            />
                                            <button
                                                v-if="temp_financial_title.multa_cobranca !== financial_title.multa_cobranca && temp_financial_title.multa_cobranca !== ''"
                                                @click="save_by_key('multa_cobranca')"
                                                style="width:70px;"
                                                class="h-100 btn btn-icon btn-success rounded-0"
                                                v-b-tooltip.hover.top="'Salvar'"
                                            >
                                                <i class="link-light ri-save-2-fill align-bottom"></i>
                                            </button>
                                            <p class="mb-0 fs-10 position-absolute" style="top:4px;left:9px;">Multa
                                                <i class="mdi text-warning mdi-pencil fs-10"></i>
                                            </p>
                                        </div>
                                    </td>
                                    <td class="p-0 border" style="height: 1px;">
                                        <div class="d-flex h-100 position-relative"> 
                                            <input 
                                                @keydown.enter.prevent="save_by_key('desconto_cobranca')" 
                                                class="form-control border-0 py-1 rounded-0 pt-3 fw-semibold" 
                                                :value="format_numeric(temp_financial_title.desconto_cobranca)"
                                                @input="temp_financial_title.desconto_cobranca = format_numeric($event.target.value)" 
                                                type="text"
                                            />
                                            <button
                                                v-if="temp_financial_title.desconto_cobranca !== financial_title.desconto_cobranca && temp_financial_title.desconto_cobranca !== ''"
                                                @click="save_by_key('desconto_cobranca')"
                                                style="width:70px;"
                                                class="h-100 btn btn-icon btn-success rounded-0"
                                                v-b-tooltip.hover.top="'Salvar'"
                                            >
                                                <i class="link-light ri-save-2-fill align-bottom"></i>
                                            </button>
                                            <p class="mb-0 fs-10 position-absolute" style="top:4px;left:9px;">Desconto
                                                <i class="mdi text-warning mdi-pencil fs-10"></i>
                                            </p>
                                        </div>
                                    </td>
                                    <td class="py-1  border" rowspan="1" style="min-width: 100px;">
                                        <p class="mb-0 fs-10">Liquido</p>
                                        <p class="fw-semibold mb-0">{{financial_title.valor_liquido.toFixed(2)}}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0" style="height: 1px;">
                                        <div class="d-flex h-100 position-relative"> 
                                            <input @keydown.enter.prevent="save_by_key('numero_titulo')" class="form-control border-0 py-1 rounded-0 pt-3 fw-semibold" v-model="temp_financial_title.numero_titulo" type="text"/>
                                            <button
                                                v-if="temp_financial_title.numero_titulo !== financial_title.numero_titulo && temp_financial_title.numero_titulo !== ''"
                                                @click="save_by_key('numero_titulo')"
                                                style="width:70px;"
                                                class="h-100 btn btn-icon btn-success rounded-0"
                                                v-b-tooltip.hover.top="'Salvar'"
                                            >
                                                <i class="link-light ri-save-2-fill align-bottom"></i>
                                            </button>
                                            <p class="mb-0 fs-10 position-absolute" style="top:4px;left:9px;">Número
                                                <i class="mdi text-warning mdi-pencil fs-10"></i>
                                            </p>
                                        </div>
                                    </td>
                                    <td colspan="100" class="p-0 border-start border" style="height: 1px;">
                                        <div class="d-flex h-100 position-relative">
                                            <textarea class="form-control border-0 py-1 rounded-0 pt-3 fw-semibold" @keydown.enter.prevent="save_by_key('observacao')" v-model="temp_financial_title.observacao"></textarea>
                                            <button
                                                v-if="temp_financial_title.observacao !== financial_title.observacao"
                                                @click="save_by_key('observacao')"
                                                style="width:70px;"
                                                class="h-100 btn btn-icon btn-success rounded-0"
                                                v-b-tooltip.hover.top="'Salvar'"
                                            >
                                                <i class="link-light ri-save-2-fill align-bottom"></i>
                                            </button>
                                            <p class="mb-0 fs-10 position-absolute" style="top:4px;left:15px;">Observação
                                                <i class="mdi text-warning mdi-pencil fs-10"></i>
                                            </p>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="table mb-0" v-if="[3, 1446489765].includes(financial_title.id_tipo_cobranca)">
                            <tbody>
                                <tr>
                                    <td class="py-1 border-start border-end">
                                        <p class="mb-0 fs-10">Boleto Registrado</p>
                                        <p class="fw-semibold mb-0">{{financial_title.boleto_emitido ? 'Sim' : 'Não'}}</p>
                                    </td>
                                    <td class="py-1 border-start border-end">
                                        <!-- <p class="mb-0 fs-10">Linha Digitável</p>
                                        <p class="fw-semibold mb-0">{{financial_title.codigo_barras_boleto}}</p> -->
                                        <div class="d-flex titulo_financeiro position-relative">
                                            <p class="mb-0 fs-10 position-absolute" style="top:4px;left:0px;">Linha Digitável
                                                <i class="mdi text-warning mdi-pencil fs-10"></i>
                                            </p>
                                            <div class="flex-1">
                                                <p class="fw-semibold mb-0 pt-3" style="cursor:pointer;">{{temp_financial_title.codigo_barras_boleto}}</p>
                                            </div>
                                            <div class="dropdown align-self-start message-box-drop rounded-0 position-static">
                                                <a class="dropdown-toggle btn btn-icon btn-light border-0 rounded" href="#" role="button" data-bs-toggle="dropdown" data-bs-popper-config='{"strategy":"fixed"}' aria-haspopup="true" aria-expanded="false" style="height:38px">
                                                    <i class="ri-more-2-fill" />
                                                </a>
                                                <div class="dropdown-menu">
                                                    <div class="list-group-item-action">
                                                        <a class="dropdown-item disabled" role="button">
                                                            <i class="link-success mdi mdi-plus-circle align-middle me-2" />
                                                            Novo
                                                        </a>
                                                    </div>
                                                    <div class="list-group-item-action" style="pointer">
                                                        <a :class="['dropdown-item', !temp_financial_title.boleto_emitido || !temp_financial_title.codigo_barras_boleto ? 'disabled' : '']" role="button" @click="showFormModal('b212ec4b-99b3-47cc-9706-d6119be70b44', temp_financial_title.id_debito_direto_autorizado + '')">
                                                            <i class="link-warning mdi mdi-pencil align-middle me-2" />
                                                            Editar
                                                        </a>
                                                    </div>
                                                    <div class="list-group-item-action" style="pointer">
                                                        <a :class="['dropdown-item', !temp_financial_title.boleto_emitido || !temp_financial_title.codigo_barras_boleto ? 'disabled' : '']" role="button" @click="callFunctionAction(function_update_bank_slip)">
                                                            <i :class="['align-middle me-2', function_update_bank_slip.icone]" />
                                                            {{function_update_bank_slip.denominacao}}
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="table mb-0" v-if="[1446489987, 1474606972, 256796645].includes(financial_title.id_tipo_cobranca)">
                            <tbody>
                                <tr>
                                    <td class="py-1 border-start border-end">
                                        <p class="mb-0 fs-10">Banco</p>
                                        <p class="fw-semibold mb-0">{{financial_title.nome_banco_credor_devedor}}</p>
                                    </td>
                                    <td class="py-1 border-start border-end">
                                        <p class="mb-0 fs-10">Agência/Conta</p>
                                        <p class="fw-semibold mb-0">{{financial_title.agencia_credor_devedor}}/{{financial_title.numero_conta_credor_devedor}}</p>
                                    </td>
                                    <td class="py-1 border-start border-end">
                                        <p class="mb-0 fs-10">Tipo da Conta</p>
                                        <p class="fw-semibold mb-0">{{financial_title.titulo_tipo_conta_credor_devedor}}</p>
                                    </td>
                                    <td class="py-1 border-start border-end">
                                        <p class="mb-0 fs-10">Tipo</p>
                                        <p class="fw-semibold mb-0">{{financial_title.titulo_tipo_chave_credor_devedor}}</p>
                                    </td>
                                    <td class="py-1 border-start border-end">
                                        <p class="mb-0 fs-10">Chave</p>
                                        <p class="fw-semibold mb-0">{{financial_title.chave_credor_devedor}}</p>
                                    </td>
                                    <td class="py-1 border-start border-end">
                                        <p class="mb-0 fs-10">QR Code</p>
                                        <template v-if="financial_title.qr_code_pix">
                                            <div v-if="showQrCode" class="d-flex flex-col gap-1 mb-1">
                                                <qrcode-vue renderAs="svg" :value="financial_title.qr_code_pix" :size="100"></qrcode-vue>
                                                <!-- <div style="line-break: anywhere;max-width:300px;">{{financial_title.qr_code_pix}}</div> -->
                                            </div>
                                            <div class="d-flex">
                                                <a @click="copyToClipBoard(financial_title.qr_code_pix)" href="javascript:void(0);" class="text-muted btn btn-icon btn-sm" v-b-tooltip.hover.top="'Copiar código'"><i class="link-success mdi mdi-content-copy align-bottom fs-15"></i></a>
                                                <div class="position-relative">
                                                    <a @click="showQrCode = !showQrCode" href="javascript:void(0);" class="text-muted ms-2 btn btn-icon btn-sm" v-b-tooltip.hover.top="'Exibir/Esconder código QR'"><i class="link-secondary mdi mdi-qrcode-scan align-bottom fs-15"></i></a>
                                                    <div id="copy-message" class="alert alert-success position-absolute d-inline-block" role="alert" style="left:-150px">
                                                        Texto copiado!!!
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="table mb-0" v-if="financial_title.situacao === 2">
                            <tbody>
                                <tr>
                                    <td class="py-1 border-start border-end">
                                        <p class="mb-0 fs-10">Data de Liquidação</p>
                                        <p class="fw-semibold mb-0">{{financial_title.data_liquidacao ? moment(financial_title.data_liquidacao).format("DD/MM/YYYY") : ''}}</p>
                                    </td>
                                    <td class="py-1 border-start border-end">
                                        <p class="mb-0 fs-10">Usuário de Liquidação</p>
                                        <p class="fw-semibold mb-0">{{financial_title.usuario_liquidacao}}</p>
                                    </td>
                                    <td class="py-1 border-start border-end">
                                        <p class="mb-0 fs-10">Documento de Liquidação</p>
                                        <p class="fw-semibold mb-0">{{financial_title.documento_especie_liquidacao}}</p>
                                    </td>
                                    <td class="py-1 border-start border-end">
                                        <p class="mb-0 fs-10">Nosso Número</p>
                                        <p class="fw-semibold mb-0">{{financial_title.nosso_numero}}</p>
                                    </td>
                                    <td class="py-1 border-start border-end">
                                        <p class="mb-0 fs-10">Valor Liquidado</p>
                                        <p class="fw-semibold mb-0">R$ {{financial_title.valor_liquidado.toFixed(2)}}</p>
                                    </td>                                   
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div class="card" v-if="showDynamicGridTable">
                <div class="card-header d-flex align-items-center gap-2 py-1">
                    <h6 v-if="financial_title.id_tipo_cobranca === 3" class="card-title fw-semibold mb-0">Configurar Boleto</h6>
                    <h6 v-if="financial_title.id_tipo_cobranca === 1474606972" class="card-title fw-semibold mb-0">Configurar Pix</h6>
                    <h6 v-if="financial_title.id_tipo_cobranca === 256796645" class="card-title fw-semibold mb-0">Configurar TED/DOC</h6>
                    <i v-if="loadingDynamicTable" class="mdi mdi-loading label-icon mdi-spin fs-20 align-middle me-2"></i>
                </div>
                <div class="card-body">
                    <div :id="dynamicGridTableId"></div>
                </div>  
            </div>

            <div class="card" v-if="showInputText">
                <div class="card-header d-flex align-items-center gap-2 py-1">
                    <h6 v-if="financial_title.id_tipo_cobranca === 1546734496" class="card-title fw-semibold mb-0">Configurar QR Code Pix</h6>
                    <h6 v-if="financial_title.id_tipo_cobranca === 1446489765" class="card-title fw-semibold mb-0">Configurar Contas, Tributos e Impostos</h6>
                </div>
                <div class="card-body">
                    <div class="col-12">
                        <form @submit.prevent>
                            <div class="input-group">
                                <input type="text" v-model="stringToBeSaved" required class="form-control"
                                    placeholder="Digite aqui...">
                            </div>
                        </form>
                    </div>
                </div>
                <div class="card-footer d-flex align-items-center agp-2 py-1">
                    <div class="col-12 w-100 d-flex align-items-center">
                        <button v-if="saveLoading" type="button"
                            class="ms-auto btn btn-primary shadow-sm col-12 col-md-3 btn-load">
                            <span class="d-flex align-items-center justify-content-center gap-2">
                                <span class="spinner-border flex-shrink-0" role="status"></span> Carregando
                            </span>
                        </button>
                        <button type="button" v-else class="ms-auto btn btn-sm btn-primary shadow-sm col-12 col-md-3" @click="saveItems">
                            <i class="ri-save-2-fill align-bottom me-1"/>
                            Salvar
                        </button>
                    </div>
                </div>
            </div>

            <div class="card" v-if="programa.dados.titulo_financeiro_chamado.registros.length">
                <div class="card-header d-flex align-items-center justify-content-between py-3">
                    <h6 class="card-title fw-semibold mb-0">Chamados Relacionados</h6>
                </div>
                <div class="card-body">
                    <div data-simplebar style="height: 300px;" class="px-3 mx-n3">
                        <table class="table table-responsive table-centered mb-0 table-bordered text-center">
                            <thead>
                            <tr>
                                <th scope="col">#Número</th>
                                <th scope="col">Anexos</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="registro in programa.dados.titulo_financeiro_chamado.registros" :key="registro.id_chamado">
                                <td>
                                <div class="d-flex align-items-center justify-content-center">
                                    <a target="_blank" :href="registro.url_registro" class="text-decoration-underline" title="Abrir em nova aba">{{ registro.id_chamado }}</a><i
                                    class="mdi mdi-arrow-top-right-bold-box fs-16 text-primary"></i>
                                </div>
                                </td>
                                <td v-if="getDocumentsByChamadoId(registro.id_chamado).length" class="p-0">
                                <table class="table table-responsive table-centered m-0 table-bordered text-center">
                                    <tr>
                                    <td class="border-0">
                                        <div class="d-flex justify-content-between align-items-center p-0">
                                            <div class="d-flex align-items-center p-0">
                                                <button type="button" @click="showFiles(getDocumentsByChamadoId(registro.id_chamado), 'chamado')" class="btn btn-ghost-secondary btn-icon btn-sm fs-18" v-b-tooltip.hover.top="'Visualizar todos os anexos'"><i class="mdi link-info mdi-file-eye align-bottom"></i></button>
                                                <button type="button" @click="downloadFile({documents: getDocumentsByChamadoId(registro.id_chamado), single: false})" class="btn btn-ghost-secondary btn-icon btn-sm fs-18" v-b-tooltip.hover.top="'Baixar todos os anexos'"><i class="mdi link-primary mdi-file-download align-bottom"></i></button>
                                            </div>
                                            <span class="small text-muted me-2">{{getDocumentsByChamadoId(registro.id_chamado).length}} Anexo(s)</span>
                                        </div>
                                    </td>
                                    </tr>
                                    <tr>
                                    <td class="border-0">
                                        <template v-for="doc in getDocumentsByChamadoId(registro.id_chamado)"
                                        :key="doc.id_documento">
                                        <div class="border border-dashed p-2 rounded my-1 mx-2 bg-light">
                                            <div class="d-flex justify-content-between">
                                            <span class="text-muted fs-10 d-block mb-2 fw-semibold">{{ doc.usuario_inclusao }} |
                                                {{ moment(doc.data_inclusao).format("DD/MM/YYYY H:mm") }}</span>
                                            <span class="text-muted fs-10 d-block mb-2 fw-semibold">#{{ doc.id_documento }}</span>
                                            </div>
                                            <div class="d-flex align-items-center text-start">
                                            <div class="flex-shrink-0 avatar-sm">
                                                <div class="avatar-title bg-light rounded">
                                                <i class="mdi mdi-file-document-outline fs-20 text-muted"></i>
                                                </div>
                                            </div>
                                            <div class="flex-grow-1 ms-3">
                                                <h6 class="mb-1">{{ doc.nome_documento }}</h6>
                                                <small class="text-muted me-2">{{ (doc.tamanho / 1048576).toFixed(2) }} MB</small>
                                            </div>
                                            <div class="hstack gap-3 fs-16">
                                                <a @click="showFiles([doc])" href="javascript:void(0);" class="text-muted"
                                                v-b-tooltip.hover.top="'Visualizar anexo'"><i
                                                    class="mdi link-info mdi-file-eye align-bottom"></i></a>
                                                <a @click="downloadFile({ documents: [doc], single: true })" href="javascript:void(0);"
                                                class="text-muted" v-b-tooltip.hover.top="'Baixar anexo'"><i
                                                    class="mdi link-primary mdi-file-download align-bottom"></i></a>
                                            </div>
                                            </div>
                                        </div>
                                        </template>
                                    </td>
                                    </tr>
                                </table>
                                </td>
                                <td v-else>
                                <span class="text-muted">Nenhum documento anexado</span>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div class="card d-lg-none">
                <div class="card-body px-0 pt-0">
                    <DocumentsVisualizationModal v-if="filesToShow.length"
                        :files="filesToShow" 
                        :original_documents="currentOriginalFiles" 
                        :showFileModal="showFileModal"
                        :setShowFileModal="(value) => showFileModal = value"
                        :onClose="() => filesToShow = []"
                        :showZoomDescription="false"
                        :inBody="true"
                        :initialIndex="programa.dados.sis_documento.registros.length -1"
                    />
                    <div v-else>
                        <p class="text-center">Nenhum documento anexado</p>
                    </div>
                </div>
            </div>
            <div class="row">      
                <div class="col-12 col-xxl-6">
                    <div class="card">
                        <div class="card-header d-flex align-items-center justify-content-between py-3">
                            <h6 class="card-title fw-semibold mb-0">Ocorrências</h6>
                            <div>
                                <button id="f_button_bc46e597-ada3-45db-b1c9-95726e764b9c"
                                    type="button" class="btn btn-ghost-secondary btn-icon btn-sm fs-20"
                                    v-b-tooltip.hover.top="'(Ctrl + Shift + Enter) ' + function_approve_payment?.denominacao"
                                    @click="callFunctionAction(function_approve_payment)">
                                    <i
                                        :class="[function_approve_payment?.icone ?? 'ri-quill-pen-fill', 'align-bottom']" />
                                </button>
                                <button id="f_button_636b7c40-7ef4-42d4-b064-f0e2d7eb7ee8"
                                    type="button" class="btn btn-ghost-secondary btn-icon btn-sm fs-20"
                                    v-b-tooltip.hover.top="'(Ctrl + Shift + /) ' + function_repprove_payment?.denominacao"
                                    @click="callFunctionAction(function_repprove_payment)">
                                    <i
                                        :class="[function_repprove_payment?.icone ?? 'ri-quill-pen-fill', 'align-bottom']" />
                                </button>
                            </div>
                            <span class="small text-muted">{{system_comments.length}} Ocorrências</span>
                        </div>
                        <div class="card-body">
                            <div data-simplebar style="height: 400px;" class="px-3 mx-n3">
                                <div v-for="sc in system_comments" :key="sc.id_ocorrencia_titulo_financeiro" class="d-flex align-items-center px-1 border-bottom mb-1 pb-1">
                                    <div style="width:32px;">
                                        <i v-if="sc.id_tipo_ocorrencia_titulo_financeiro === 1" class="mdi mdi-calendar-plus fs-20 text-muted"></i>
                                        <i v-if="sc.id_tipo_ocorrencia_titulo_financeiro === 2" class="mdi mdi-thumb-up fs-20 text-success"></i>
                                        <i v-if="sc.id_tipo_ocorrencia_titulo_financeiro === 3" class="mdi mdi-cash-check fs-20 text-secondary"></i>
                                        <i v-if="sc.id_tipo_ocorrencia_titulo_financeiro === 4" class="mdi mdi-thumb-down fs-20 text-danger"></i>
                                    </div>
                                    <div class="flex-grow-1 ms-3">
                                        <h6 class="mb-0">{{sc.usuario_inclusao}}</h6>
                                        <small class="d-block text-muted">{{moment(sc.data_inclusao).format("DD/MM/YYYY H:mm")}} {{sc.porcentagem_aprovacao ? ' - ' + sc.porcentagem_aprovacao + '%' : ''}}</small>
                                    </div>
                                    <button v-if="current_user.id_usuario === sc.id_usuario_inclusao && [2, 4].includes(sc.id_tipo_ocorrencia_titulo_financeiro)"
                                        class="btn btn-icon btn-light btn-sm"
                                        @click="deleteOccurrence(sc.id_ocorrencia_titulo_financeiro)" v-b-tooltip.hover.top="'Excluir Ocorrência'"
                                    >
                                        <i class="mdi link-danger mdi-trash-can fs-16"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-7">
            <div class="card d-none d-lg-block">
                <div class="card-body px-0 pt-0">
                    <DocumentsVisualizationModal v-if="filesToShow.length"
                        :files="filesToShow" 
                        :original_documents="currentOriginalFiles" 
                        :showFileModal="showFileModal"
                        :setShowFileModal="(value) => showFileModal = value"
                        :onClose="() => filesToShow = []"
                        :showZoomDescription="false"
                        :inBody="true"
                        :initialIndex="programa.dados.sis_documento.registros.length -1"
                    />
                    <div v-else>
                        <p class="text-center">Nenhum documento anexado</p>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header d-flex align-items-center justify-content-between py-1 border-0">
                    <div class="d-flex align-items-center">
                        <h6 class="card-title fw-semibold mb-0 me-2">Anexos</h6>
                        <button @click="showFiles(documents)" class="btn btn-ghost-secondary btn-icon btn-sm fs-18" v-b-tooltip.hover.top="'Visualizar todos os anexos'"><i class="mdi link-info mdi-file-eye align-bottom"></i></button>
                        <button id="downloadFilesButton" @click="downloadFile({documents, single: false})" class="btn btn-ghost-secondary btn-icon btn-sm fs-18" v-b-tooltip.hover.top="'Baixar todos os anexos'"><i class="mdi link-primary mdi-file-download align-bottom"></i></button>
                        <button @click="setShowUploadFileModal" class="btn btn-ghost-secondary btn-icon btn-sm fs-18" v-b-tooltip.hover.top="'Anexar documentos'"><i class="mdi link-success mdi-file-plus align-bottom"></i></button>
                        <button id="f_button_ffafbfeb-a936-4087-9d76-ad4b3c18dd57"
                            type="button" class="btn btn-ghost-secondary btn-icon btn-sm fs-20"
                            v-b-tooltip.hover.top="'(Ctrl + Shift + 8) ' + function_generate_receipt?.denominacao"
                            @click="callFunctionAction(function_generate_receipt)">
                            <i :class="[function_generate_receipt?.icone ?? 'ri-quill-pen-fill', 'align-bottom']" />
                        </button>
                    </div>
                    <span class="small text-muted">{{documents.length}} Anexo(s)</span>
                </div>
                <div class="card-body px-3">
                    <div class="table-responsive table-card">
                        <table class="table table-bordered mb-0">
                            <tbody>
                                <tr v-for="document in documents" :key="document.id_documento">
                                    <td class="py-1">{{document.usuario_inclusao}}</td>
                                    <td class="py-1">{{moment(document.data_inclusao).format("DD/MM/YYYY H:mm")}}</td>
                                    <td class="py-1">
                                        <div class="d-flex flex-column">
                                            <p class="mb-0">{{document.nome_documento}}</p>
                                            <p class="mb-0 text-muted fs-11">{{document.descricao}}</p>
                                        </div>
                                    </td>
                                    <td class="py-1">{{ (document.tamanho / 1048576).toFixed(2) }} MB</td>
                                    <td class="py-1">
                                        <div class="hstack gap-2 fs-16 justify-content-center">
                                            <a v-if="function_show_document" @click="showFiles([document])" href="javascript:void(0);" class="text-muted" v-b-tooltip.hover.top="'Visualizar anexo'"><i class="mdi link-info mdi-file-eye align-bottom"></i></a>
                                            <a v-if="function_download_document" @click="downloadFile({documents: [document], single: true})" href="javascript:void(0);" class="text-muted" v-b-tooltip.hover.top="'Baixar anexo'"><i class="mdi link-primary mdi-file-download align-bottom"></i></a>
                                            <i v-if="document.loading" class="mdi mdi-loading mdi-spin fs-20 align-middle me-2"></i>
                                            <a v-else-if="function_remove_document" @click="remove_document(document)" href="javascript:void(0);" class="text-muted" v-b-tooltip.hover.top="'Excluir anexo'"><i class="mdi link-danger mdi-file-remove align-bottom"></i></a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="titulo-financeiro-upload" title="Adicione Arquivos" fade scrollable centered size="lg" hide-footer
            v-model="showUploadFileModal">
            <uploadFile :updateFile="(data) => (files = data)"  :state="showUploadFileModal"/>
            <div class="d-grid gap-2">
                <div v-if="loadingFileUpload" class="text-center">
                    <b-spinner variant="success" />
                </div>
                <b-button v-else class="btn btn-sm btn-success" @click="saveFiles()">
                    Salvar
                </b-button>
            </div>
        </b-modal>
        <ContasPagarContainer />
        <SimpleLoadigPage :state="loading_financial_title" :title="loadingTitle"/>
        <div class="card-body chat-container mb-2">
            <ChatBox
                :chatMessagesData="movimentoMessages"
                :onSubmitNewMessage="handleSubmitMessage"
                :onDeleteMessage="handleDeleteMessage"
                :maxHeight="'350px'"
                :minimized="true"
            />
        </div>
    </div>
    <DocumentsVisualizationModal
        :files="filesToShow2" 
        :original_documents="currentOriginalFiles2" 
        :showFileModal="showFileModal2"
        :setShowFileModal="(value) => showFileModal2 = value"
        :onClose="() => filesToShow2 = []"
        :showZoomDescription="false"
    />
    <b-modal v-model="showProgramForm" fade scrollable centered size="lg" hide-footer hide-header no-enforce-focus>
        <div class="modal-content" style="
            width: 95vw;
            height: 95vh;
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        ">
            <div class="modal-header">
                <h5 class="modal-title" tabindex="0">{{programFormSchema?.denominacao}}</h5>
            </div>
            <div class="modal-body">
                <template v-if="loadingProgramForm">
                    <div class="d-flex flex-column justify-content-center align-items-center h-100">
                        <b-spinner variant="primary" label="Carregando..." />
                        <span class="mt-1">Carregando formulário...</span>
                    </div>
                </template>
                <div v-else-if="component">
                    <component
                        :is="component"
                        :program_schema="programFormSchema"
                        :dados="programFormDados"
                        :inModal="true"
                        :registro_id="programFormIdRegistro"
                        :formType_="'edition'"
                        :onSuccess="programFormOnSucces"
                    />
                </div>
            </div>
            <div class="modal-footer border-top">
                <button @click="() => showProgramForm = false" class="btn btn-primary me-auto" v-b-tooltip.hover.top="'ESC'">Sair</button>
            </div>
        </div>
    </b-modal>
</template>

<style @scoped>
</style>