<script>
export default {
    props: {
        state: {
            type: Boolean,
            required: true,
        },
        title: {
            type: String,
            required: false,
        },
    },
};
</script>
<template>
    <div v-if="this.$props.state" id="simple-loading-page" class="position-fixed top-0 bottom-0 start-0 end-0 d-flex justify-content-center align-items-center" style="z-index: 100;background:rgba(0, 0, 0, 65%);">
        <div class="p-3 rounded text-center">
            <b-spinner variant="success"></b-spinner>
            <p class="mb-0 text-white">{{ this.$props.title }}...</p>
        </div>
    </div>
</template>