<script>
import { ref, watch } from 'vue';
import { useStore } from 'vuex';
import { sendFunctionRequest } from '../../helpers/dynamicTable/sendFunctionRequest';
import useProgram from '../../composables/program';
import CustomInput from '@/components/custom_input/index.vue';
import CKEditor from "@ckeditor/ckeditor5-vue"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Swal from 'sweetalert2';
import { v4 as uuidv4 } from 'uuid'
import { getValueListById } from '../../helpers/forms/getListaValorById';

export default {
    props: {
    },
    setup() {
        const store = useStore();
        const show = ref(false);
        const saveLoading = ref(false)
        const loadingSchema = ref(false)
        const program_schema = ref(null)
        const baseForm = ref(null)
        const loadingBaseForm = ref(false)
        const formElement = ref(null)
        const handleProgramFormSubmit = ref(null)
        const editorData = ref('')
        const tipoChamadoOptionsList = ref([])
        
        watch(() => store.state.massActionsModals.showOpenTicketModal, (value) => {
            show.value = value;
            if(value) {
                editorData.value = ''
                getProgramSchema()
            }
        })
        watch(() => show.value, (value) => {
            if(!value) {
                saveLoading.value = false
                store.commit('massActionsModals/setProperty', [
                    {
                        key: 'showOpenTicketModal',
                        value: false
                    }
                ])
                
                if(document.getElementsByTagName('body')[0].attributes['data-bs-padding-right'])
                document.getElementsByTagName('body')[0].attributes.removeNamedItem('data-bs-padding-right')
            }
        })
        
        async function getProgramSchema() {
            loadingSchema.value = true
            const defaultBody = {
                id_programa: 'e7985cfb-b2bc-4df9-b146-12cc9d6cbbfa',
                condicao_adicional: "",
                lista_id_empresa: JSON.parse(localStorage.getItem('companiesGlobalFilter')) ?? [],
                data_trabalho: JSON.parse(localStorage.getItem('dataTrabalho')),
                navegador: false,
                ordenacao: null,
                quantidade_registros: 0,
                pagina: 0,
                rota: 'obtem_dados_programa_usuario',
            }
            const res = await sendFunctionRequest(defaultBody)
            loadingSchema.value = false
            if(!res.data.status) return
            
            program_schema.value = res.data.retorno.sis_programa
            program_schema.value.id_programa = store.state.massActionsModals.program_schema.id_programa
            const programa = {
                program_schema: program_schema.value,
                dados: [],
                registro_id: null
            }
            const {
                setProgramForm,
                handleFormSubmit,
                baseFormModifier,
                loading,
            } = useProgram('creation', programa)
            setProgramForm()
            baseForm.value = baseFormModifier.value
            handleProgramFormSubmit.value = handleFormSubmit
            loadingBaseForm.value = loading
        }

        const handleFormSubmit = async () => {
            saveLoading.value = true
            const additional_data = store.state.massActionsModals.additional_data
            const sis_programa = store.state.massActionsModals.program_schema
            const route = sis_programa.rota_backend + sis_programa.sis_funcionalidade.find(sf => sf.id_funcionalidade === '6135668e-b9da-4873-9453-5d7855c42156').rota_backend
            const res = await handleProgramFormSubmit.value({target: formElement.value}, [additional_data], route, false, true)
            saveLoading.value = false
            if(!res) return
            if(res && !res.status){
                return Swal.fire({
                    icon: "error",
                    text: res.mensagem ? res.mensagem : res.erro,
                    confirmButtonColor: "#34c38f",  
                    confirmButtonText: "OK",
                });
            }
            show.value = false
            Swal.fire({
                icon: "success",
                text: "Chamado aberto com sucesso!",
                confirmButtonColor: "#34c38f",  
                confirmButtonText: "OK",
            });
        }

        const editor = ClassicEditor
        const editorConfig = {
            toolbar: [
                'heading',
                '|',
                'bold',
                'italic',
                'link',
                'bulletedList',
                'numberedList',
                '|',
                'undo',
                'redo'
            ],
            translations: []
        }
        const handleEditorDataChange = (value) => {
            baseForm.value.setMutatedValues('descricao', value)
        }

        const setTipoChamadoOptionsList = () => {
            if(!baseForm.value.mutatedValues.id_departamento || isNaN(baseForm.value.mutatedValues.id_departamento)){
                baseForm.value.setMutatedValues('tipo', null)
                tipoChamadoOptionsList.value = []
                return
            }
            getValueListById('12df672c-6fdc-44e3-881a-9ebea7a030d6', 'id_departamento = ' + baseForm.value.mutatedValues.id_departamento)
            .then(res => {
                baseForm.value.setMutatedValues('tipo', null)
                const { sis_lista_valor: { sis_opcao_lista_valor } } = res
                tipoChamadoOptionsList.value = sis_opcao_lista_valor ?? []
            })
            .catch((error) => {
                Swal.fire({
                    icon: "error",
                    text: error,
                    confirmButtonColor: "#34c38f",
                    confirmButtonText: "OK",
                });
                console.log(error)
            })
        }

        return {
            formId: uuidv4(),
            setTipoChamadoOptionsList,
            tipoChamadoOptionsList,
            handleEditorDataChange,
            editor,
            editorData,
            editorConfig,
            show,
            saveLoading,
            loadingSchema,
            baseForm,
            handleFormSubmit,
            loadingBaseForm,
            formElement
        }
    },
    components: {
        CustomInput,
        CKEditor: CKEditor.component
    }
}
</script>

<template>
    <b-modal size="lg" v-model="show" centered scrollable :title="$store.state.massActionsModals?.title">
        <div>
            <div v-if="loadingSchema || !baseForm" class="row mb-4">
                <div class="col-12">
                    <span  class="d-flex align-items-center justify-content-center text-primary gap-2">
                        <span class="spinner-border flex-shrink-0" role="status"></span>
                    </span>
                </div>
            </div>
            <div v-else class="row">
                <div class="my-2 mb-3">
                    <form @submit.prevent="handleFormSubmit" ref="formElement" class="needs-validation" novalidate :id="formId">
                        <div class="border p-3 mb-0">
                            <div class="row">
                                <div class="col-12 col-md-6">
                                    <div class="mb-3">
                                        <CustomInput :formState="baseForm" formType="creation"
                                            :columnSchema="baseForm.getColumnByName('id_empresa')"
                                            :showLabel="true" />
                                    </div>
                                </div>
                                <div class="col-12 col-md-6">
                                    <div class="mb-3">
                                        <CustomInput :formState="baseForm" formType="creation"
                                            :columnSchema="baseForm.getColumnByName('id_departamento')"
                                            :showLabel="true"
                                            :onValueChange="setTipoChamadoOptionsList"
                                            />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-md-6">
                                    <div class="mb-3">
                                        <CustomInput :formState="baseForm" formType="creation"
                                            :columnSchema="baseForm.getColumnByName('tipo')"
                                            :showLabel="true"
                                            :customOptionsList="tipoChamadoOptionsList"
                                        />
                                    </div>
                                </div>
                                <div class="col-12 col-md-6">
                                    <div class="mb-3">
                                        <CustomInput :formState="baseForm" formType="creation"
                                            :columnSchema="baseForm.getColumnByName('titulo')"
                                            :showLabel="true" />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-md-6">
                                    <div class="mb-3">
                                        <CustomInput :formState="baseForm" formType="creation"
                                            :columnSchema="baseForm.getColumnByName('data_vencimento')"
                                            :showLabel="true" />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <label>Descrição <span class="text-danger" v-if="baseForm.getColumnByName('descricao').requerido">*</span></label>
                                    <div id="ckeditor-chamado">
                                        <CKEditor :editor="editor" v-model="editorData" :config="editorConfig" @input="handleEditorDataChange"/>
                                    </div>
                                    <CustomInput
                                        :formState="baseForm"
                                        formType="creation"
                                        :columnSchema="baseForm.getColumnByName('descricao')"
                                        :showLabel="false"
                                        inputClass="d-none"
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

        </div>
        <template #footer>
            <div class="row w-100">
                <div class="d-flex align-items-center">
                    <span>{{$store.state.massActionsModals.additional_data?.data?.length > 0 ? $store.state.massActionsModals.additional_data.data.length + ' Registro(s) selecionado(s)' : 'Nenhum registro selecionado'}}</span>
                    <button v-if="saveLoading" type="button"
                        class="ms-auto btn btn-primary shadow-sm col-12 col-md-3 btn-load"
                        style="height: 40px; min-width: 100px;">
                        <span class="d-flex align-items-center justify-content-center gap-2">
                            <span class="spinner-border flex-shrink-0" role="status"></span> Carregando
                        </span>
                    </button>
                    <button type="button" v-else
                        class="ms-auto btn btn-primary shadow-sm col-12 col-md-3"
                        style="height: 40px; min-width: 100px;"
                        @click="handleFormSubmit"
                    ><i class="ri-save-2-fill align-bottom me-1"></i> Salvar</button>
                </div>
            </div>
        </template>
    </b-modal>
</template>