import { Grid, h } from "gridjs"
import { RowSelection } from "gridjs/plugins/selection/dist/selection"
import { ref } from "vue"
import ptBR from "./ptBrLanguage"
import { makeResizable } from "../makeResizable"
import formatColumnValue from "./formatColumnValue"


//eslint-disable-next-line
export default function useValueListTable(
  grid,
  gridContainerName,
  table_schema,
  update_selected_items,
  onSelectItem,
) {
  grid.value = new Grid()
  const currentSelectedRows = ref([])
  const currentDataIds = ref([])
  const currentPageData = ref([])

  function setTableColumns(registros) {
    const columns_schema = table_schema
    const columns = []
    currentPageData.value = registros

    columns_schema.unshift({
      titulo: 'ID',
      nome: 'id'
    })

    columns_schema.map(col => {
      columns.push({
        id: col.nome,
        name: col.titulo,
        hidden: col.nome == 'id' ? true : false,
        formatter: (cell) => {
          return h('div', {
            className: '',
            children: [cell],
            style: 'white-space:nowrap; text-align: left;padding-left: 2px'
          })
        },
      })
    })

    columns.unshift(
      {
        id: 'tableCheckbox',
        name: h('button', {
          className: 'w-100 text-primary btn btn-icon fs-22 rounded-0 h-100',
          children: h('i', { className: 'ri-check-double-fill' }),
          onClick: () => {
            selectOrUnselectAllRows();
          },
        }),
        plugin: {
          component: RowSelection,
          props: {
            id: (row) => row.cell(1).data
          }
        },
        sort: false,

        attributes: {
          style: 'text-align: center;padding: 0;width:35px;',
        },
        resizable: false,
      },
    );

    grid.value
    .updateConfig({
      columns: columns,
      data: [],
      sort: {
        enabled: true,
        multiColumn: true,
      },
      resizable: true,
      language: ptBR,
      className: {
        footer: 'pt-0',
        td: 'px-1 py-0',
        th: 'position-relative',
        table: 'position-relative',
      },
    })
    .render(document.getElementById(gridContainerName));

    grid.value.on('cellClick', (event, cell, column, row) => {
      if (column.id == 'tableCheckbox') {
        if(onSelectItem){
          onSelectItem(row.cell(1).data)
          return event.preventDefault()
        }else{
          const rowTargetDataID = row.cell(1).data
          const storeSelectedRows = grid.value.config.plugin.get('tableCheckbox').props.store.state.rowIds;
          if (storeSelectedRows.includes(rowTargetDataID)) {
            grid.value.config.dispatcher.dispatch({
              type: 'UNCHECK',
              payload: {
                ROW_ID: rowTargetDataID
              }
            })
          } else {
            grid.value.config.dispatcher.dispatch({
              type: 'CHECK',
              payload: {
                ROW_ID: rowTargetDataID
              }
            })
          }
          updateCurrentSelectedRows()
          return event.preventDefault()
        }
      }
    })
  }

  async function updateConfig(registros, onReadyCallback = null) {
    const columnValues = []
    currentDataIds.value = []
    emptyPreviousRowSelections()

    grid.value.updateConfig({
      columns: [
        {
          ...grid.value.config.columns[0],
          plugin: {
            component: RowSelection,
            props: {
              id: (row) => row.cell(1).data
            }
          },
        },
        ...grid.value.config.columns.filter((column, index) => index > 0)
      ],
      data: () => {
        // registros.map(registro => {
        //   currentDataIds.value.push(registro.id)
        //   columnValues.push(registro)
        // })

        registros.forEach(register => {
          columnValues.push(
            table_schema.map(col => {
              if(col.nome == 'id'){
                currentDataIds.value.push(register.id)
              }
              if(col.tipo_campo){
                return formatColumnValue(col, register)
              }
              return register[col.nome]
            })
          )
        })
        
        return columnValues
      }
    })

    currentPageData.value = registros

    await grid.value.forceRender()
    grid.value.on('ready', () => {
      try{
        const targetTable = ref(document.getElementById(gridContainerName).querySelector(`.gridjs-wrapper .gridjs-table`))
        const observableTargetTable = document.getElementById(gridContainerName).querySelector(`.gridjs-wrapper .gridjs-table`)
        const resizeObserver = new ResizeObserver((entries) => {
          for (const entry of entries) {
            if (entry.contentRect && entry.contentRect.width > 0) {
              makeResizable(targetTable)
              resizeObserver.unobserve(observableTargetTable)
            }
          }
        });
        resizeObserver.observe(observableTargetTable)
      }catch(e){
        console.log(e)
      }

      if (onReadyCallback) {
        onReadyCallback()
      }
    })
  }

  function selectOrUnselectAllRows() {
    const checkboxPlugin = grid.value.config.plugin.get('tableCheckbox')
    const selectedRows = checkboxPlugin.props.store.state.rowIds

    const allSelected = currentDataIds.value.every(id => selectedRows.includes(id));

    if (!allSelected) {
      currentDataIds.value.forEach((id) => {
        if (!selectedRows.includes(id)) {
          grid.value.config.dispatcher.dispatch({
            type: 'CHECK',
            payload: {
              ROW_ID: id,
            },
          });
        }
      })
    } else {
      currentDataIds.value.forEach((id) => {
        grid.value.config.dispatcher.dispatch({
          type: 'UNCHECK',
          payload: {
            ROW_ID: id
          }
        });
      })
    }

    updateCurrentSelectedRows();
  }


  function selectRowsById(ids) {
    ids.map(id => {
      grid.value.config.dispatcher.dispatch({
        type: 'CHECK',
        payload: {
          ROW_ID: id
        }
      });
    })

    updateCurrentSelectedRows();
  }

  function unselectRowsById(ids) {
    ids.map(id => {
      grid.value.config.dispatcher.dispatch({
        type: 'UNCHECK',
        payload: {
          ROW_ID: id
        }
      });
    })

    updateCurrentSelectedRows();
  }

  /** Clean previous selection state */
  function emptyPreviousRowSelections() {
    currentDataIds.value.map((id) => {
      grid.value.config.dispatcher.dispatch({
        type: "UNCHECK",
        payload: {
          ROW_ID: id,
        },
      });
    });

    updateCurrentSelectedRows();
  }

  /**
   * First render of table data, the subsequent renders like "next/prev pagination" will be handled and called by the table itself
   * @param {String} dataUrl
   */

  function updateCurrentSelectedRows() {
    try {
      currentSelectedRows.value = grid.value.config.plugin.get('tableCheckbox').props.store.state.rowIds
      update_selected_items(currentSelectedRows.value);
    } catch (e) {
      currentSelectedRows.value = [];
    }

    return currentSelectedRows.value.length;
  }

  function getRowRegister(targetId) {
    const data = currentPageData.value.find(data => data['id'] == targetId);

    return data
  }

  function updateCurrentDataIds(ids) {
    currentDataIds.value = ids
  }

  return {
    setTableColumns,
    currentDataIds,
    emptyPreviousRowSelections,
    currentSelectedRows,
    updateCurrentDataIds,
    getRowRegister,
    updateCurrentSelectedRows,
    updateConfig,
    selectRowsById,
    unselectRowsById,
    selectOrUnselectAllRows
  };
}
