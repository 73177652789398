<script>
import { useStore } from 'vuex';
import useProgram from '../../../../composables/program';
import { onMounted, computed, provide, ref, watch } from 'vue';
import TabelaDetalhe from '@/components/tabela_detalhe/index.vue';
import FormBotoes from '@/components/forms_bottom_buttons/index.vue'
import CustomInput from '@/components/custom_input/index.vue';
import PowerBI from '@/components/graficos/powerbi.vue'
import { v4 as uuidv4 } from 'uuid'
import { useRoute } from 'vue-router'
import ChatBoxController from '@/components/chat/chat-box-controller'
import ChatBox from "@/components/chat/chat-box.vue";

export default {
    props: {
        program_schema: {
            type: Object,
            required: true,
        },
        dados: {
            type: Object,
            required: true,
        },
        registro_id: {
            type: String,
            required: false,
        },
        inModal: {
            type: Boolean,
            required: false,
            default: false,
        },
        onSuccess: {
            type: Function,
            required: false,
        },
        formType_: {
            type: String,
            required: false,
        },
    },
    setup(props) {
        const store = useStore();
        const route = useRoute();
        const filterPBI = []
        const formType = props.formType_ ? props.formType_ : store.state.programForm.formType;
        const refreshSignal = ref(false);
        const programa = {
            program_schema: props.program_schema,
            dados: props.dados,
            registro_id: props.registro_id
        };
        let handleSubmitMessage = null
        let handleDeleteMessage = null
        let movimentoMessages = null
        let chatController = null

        const {
            setProgramForm,
            addNewDetailTableForm,
            removeDetailTableForm,
            handleFormSubmit,
            sis_detail_table,
            baseFormModifier,
            loading,
            btnloading,
            resetMainAndDetailTableForms
        } = useProgram(formType, programa);

        onMounted(() => {
            setProgramForm();
            triggerRefresh();
        });

        if (formType !== 'creation') {
            const primaryKeyName = programa.program_schema.sis_tabela.sis_campo_tabela.find(
                (col) => col.chave_primaria
            ).nome;
            const registerTargetId = programa.dados[programa.program_schema.sis_tabela.nome_tabela].registros[0][primaryKeyName]
            chatController = ref(new ChatBoxController(programa.program_schema, programa.dados, route.params.targetId, registerTargetId))
            chatController.value.setMovimentoMessages(props.dados.sis_anotacao?.registros || [])
            handleSubmitMessage = (message, id) => chatController.value.handleSubmitMessage(message, id)
            handleDeleteMessage = (id) => chatController.value.handleDeleteMessage(id)
            movimentoMessages = computed(() => chatController.value.getMessages())
        }

        provide('resetMainAndDetailTableForms', resetMainAndDetailTableForms)
        provide('id_empresa', programa.dados?.cotacao.registros[0]?.id_empresa ?? null)
        filterPBI.push({
            parametro_filtro: [props.dados?.cotacao.registros[0]?.id_cotacao],
            tabela_filtro: 'public vw_item_cotacao',
            coluna_filtro: 'id_cotacao'
        })
        watch(() => store.state.algumaMutacao, (newValue, oldValue) => {
            if (newValue == !oldValue) {
                triggerRefresh();
            }
        });
        function triggerRefresh() {
            refreshSignal.value = !refreshSignal.value;
        }
        return {
            formId: uuidv4(),
            handleDeleteMessage,
            handleSubmitMessage,
            movimentoMessages,
            chatController,
            formType,
            loading,
            btnloading,
            handleFormSubmit,
            baseFormModifier,
            sis_detail_table,
            addNewDetailTableForm,
            removeDetailTableForm,
            filterPBI,
            refreshSignal
        };
    },
    components: {
        TabelaDetalhe,
        FormBotoes,
        CustomInput,
        PowerBI,
        ChatBox
    },
    computed: {
        panels() {
            return this.$store.state.painelPrograma.painels;
        },
        activerefresh() {
            return this.$store.state.painelPrograma.refreshPBI;
        },
    }

}
</script>
<template>
    <div class="row">
        <div class="card">
            <div class="my-2 mb-3">
                <template v-if="loading">
                    <div class="text-center">
                        <b-spinner variant="primary"></b-spinner>
                    </div>
                </template>
                <form v-else
                    @submit.prevent="handleFormSubmit($event, [], null, false, false, null, this.$props.onSuccess)"
                    :id="formId" class="needs-validation" novalidate>
                    <ul class="nav nav-tabs mb-3" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" data-bs-toggle="tab"
                                :href="'#arrow-program' + program_schema.id_programa + program_schema.id_tabela"
                                role="tab" aria-selected="true">
                                <span>{{ program_schema.sis_tabela.denominacao }}</span>
                            </a>
                        </li>

                        <li v-for="detail_table in sis_detail_table?.filter(detail => detail.detailTableIdName !== 'sis_anotacao') "
                            :key="detail_table.schema.id_tabela" class="nav-item">
                            <a class="nav-link" data-bs-toggle="tab"
                                :href="'#arrow-' + program_schema.id_programa + detail_table.schema.id_tabela"
                                role="tab">
                                <span class="">{{ detail_table.title }}</span>
                            </a>
                        </li>
                    </ul>

                    <!-- Tab pane base form -->
                    <div class="tab-content">
                        <div class="tab-pane active"
                            :id="'arrow-program' + program_schema.id_programa + program_schema.id_tabela"
                            role="tabpanel">
                            <div class="border p-3 mb-0">
                                <div class="row">
                                    <div class="col-12 col-md-4">
                                        <div class="mb-3 flex-1">
                                            <CustomInput :formState="baseFormModifier" :formType="formType"
                                                :columnSchema="baseFormModifier.getColumnByName('id_empresa')"
                                                :showLabel="true" />
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-4">
                                        <div class="mb-3 flex-1">
                                            <CustomInput :formState="baseFormModifier" :formType="formType"
                                                :columnSchema="baseFormModifier.getColumnByName('numero')"
                                                :showLabel="true" />
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-4">
                                        <div class="mb-3 flex-1">
                                            <CustomInput :formState="baseFormModifier" :formType="formType"
                                                :columnSchema="baseFormModifier.getColumnByName('tipo_aplicacao')"
                                                :showLabel="true" />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-md-4">
                                        <div class="mb-3 flex-1">
                                            <CustomInput :formState="baseFormModifier" :formType="formType"
                                                :columnSchema="baseFormModifier.getColumnByName('id_responsavel')"
                                                :showLabel="true" />
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-4">
                                        <div class="mb-3 flex-1">
                                            <CustomInput :formState="baseFormModifier" :formType="formType"
                                                :columnSchema="baseFormModifier.getColumnByName('titulo_situacao')"
                                                :showLabel="true" />
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-4">
                                        <div class="mb-3">
                                            <CustomInput :formState="baseFormModifier" :formType="formType"
                                                :columnSchema="baseFormModifier.getColumnByName('data_validade')"
                                                :showLabel="true" />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-md-12">
                                        <div class="mb-3 flex-1">
                                            <CustomInput :formState="baseFormModifier" :formType="formType"
                                                :columnSchema="baseFormModifier.getColumnByName('observacao')"
                                                :showLabel="true" :textArea="true" :textAreaRowsNumber="2" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="border p-3 mt-2">
                                <div class="row">
                                    <div class="col-12 col-md-4">
                                        <div class="mb-3">
                                            <CustomInput :formState="baseFormModifier" :formType="formType"
                                                :columnSchema="baseFormModifier.getColumnByName('id_cotacao')"
                                                :showLabel="true" />
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-4">
                                        <div class="mb-3">
                                            <CustomInput :formState="baseFormModifier" :formType="formType"
                                                :columnSchema="baseFormModifier.getColumnByName('data_inclusao')"
                                                :showLabel="true" />
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-4">
                                        <div class="mb-3">
                                            <CustomInput :formState="baseFormModifier" :formType="formType"
                                                :columnSchema="baseFormModifier.getColumnByName('id_usuario_inclusao')"
                                                :showLabel="true" />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-md-4">
                                        <div class="mb-3">
                                            <CustomInput :formState="baseFormModifier" :formType="formType"
                                                :columnSchema="baseFormModifier.getColumnByName('uuid_cotacao')"
                                                :showLabel="true" />
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-4">
                                        <div class="mb-3">
                                            <CustomInput :formState="baseFormModifier" :formType="formType"
                                                :columnSchema="baseFormModifier.getColumnByName('data_aprovacao')"
                                                :showLabel="true" />
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-4">
                                        <div class="mb-3">
                                            <CustomInput :formState="baseFormModifier" :formType="formType"
                                                :columnSchema="baseFormModifier.getColumnByName('id_usuario_aprovacao')"
                                                :showLabel="true" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-for="detail_table in sis_detail_table" :key="detail_table.schema.id_tabela"
                            :id="'arrow-' + program_schema.id_programa + detail_table.schema.id_tabela" class="tab-pane"
                            role="tabpanel">
                            <div class="border p-3 mb-0">
                                <TabelaDetalhe :formType="formType" :table="detail_table"
                                    :mainTableFormModifier="baseFormModifier" @addRow="addNewDetailTableForm"
                                    @removeRow="removeDetailTableForm" :programSchema="this.$props.program_schema" />
                            </div>
                        </div>
                    </div>

                    <div class="">
                        <FormBotoes :formType="formType" :loading="btnloading" :inModal="inModal" />
                    </div>
                </form>
            </div>
        </div>
        <div v-for="(panel, index) in panels" :key="index">
            <PowerBI :powerBIData="panel" :id="panel.id_painel" :filterPBI="filterPBI" :refreshSignal="refreshSignal">

            </PowerBI>
        </div>

        <div v-if="formType !== 'creation'" class="chat-container">
            <ChatBox :chatMessagesData="movimentoMessages" :onSubmitNewMessage="handleSubmitMessage"
                :onDeleteMessage="handleDeleteMessage" :maxHeight="'350px'" :minimized="true" />
        </div>
    </div>
</template>