<script>
import { ref, watch } from 'vue';
import { useStore } from 'vuex';
export default {
    props: {
    },
    setup() {
        const store = useStore();
        const show = ref(false);
        const saveLoading = ref(false)
        const registersController = ref([])
        const registers = ref([])
        const anotacao = ref(null)
        
        watch(() => store.state.massActionsModals.showPagamentoReprovadoModal, (value) => {
            show.value = value;
            if(value) {
                for(let i = 0; i < store.state.massActionsModals.objects.length; i++) {
                    const data = store.state.massActionsModals.objects[i]
                    registersController.value.push(data)
                }
            }
        })
        watch(() => show.value, (value) => {
            if(!value) {
                saveLoading.value = false
                store.commit('massActionsModals/setProperty', [
                    {
                        key: 'showPagamentoReprovadoModal',
                        value: false
                    }
                ])
                registersController.value = []
                registers.value = []
                anotacao.value = null
                
                if(document.getElementsByTagName('body')[0].attributes['data-bs-padding-right'])
                document.getElementsByTagName('body')[0].attributes.removeNamedItem('data-bs-padding-right')
            }
        })
        
        const save = async () => {
            registers.value = [
                { anotacao: anotacao.value }
            ];
            saveLoading.value = true
            await store.state.massActionsModals.onSave({ sis_anotacao: { registros: registers.value }, titulo_financeiro: { registros: registersController.value }})
            show.value = false
            saveLoading.value = false
        }
        return {
            show,
            saveLoading,
            save,
            registers,
            registersController,
            anotacao
        }
    },
}
</script>

<template>
    <b-modal size="lg" v-model="show" centered scrollable :title="$store.state.massActionsModals?.title">
        <div>
            <div class="row mb-4">
                <div class="col-12">
                    <textarea
                        class="form-control border-dashed rounded-0"
                        v-model="anotacao"
                        rows="4"
                        placeholder="Digite o motivo da rejeição aqui..."
                    />
                </div>
            </div>
        </div>
        <template #footer>
            <div class="row w-100">
                <button v-if="saveLoading" type="button"
                    class="mt-2 ms-auto btn btn-primary shadow-sm col-12 col-md-3 btn-load"
                    style="height: 40px; min-width: 100px;">
                    <span class="d-flex align-items-center justify-content-center gap-2">
                        <span class="spinner-border flex-shrink-0" role="status" />
                        Carregando
                    </span>
                </button>
                <button type="button" v-else class="mt-2 ms-auto btn btn-primary shadow-sm col-12 col-md-3"
                    style="height: 40px; min-width: 100px;" @click="save">
                    <i class="ri-save-2-fill align-bottom me-1" />
                    Salvar
                </button>
            </div>
        </template>
    </b-modal>
</template>