<script>
import { ref, watch } from 'vue';
import { useStore } from 'vuex';
import { sendFunctionRequest } from '../../helpers/dynamicTable/sendFunctionRequest';

export default {
    setup() {
        const store = useStore();
        const show = ref(false);
        const stringToBeSaved = ref('')
        const title = ref(null)
        const denominacao = ref('')
        const saveLoading = ref(false)
        const p_function = ref(null)
        const programSchema = ref(null)
        const registerId = ref(null)
        
        watch(() => store.state.massActionsModals.showConfigModal, (value) => {
            show.value = value;
        })
        watch(() => show.value, (value) => {
            if (value) {
                p_function.value = store.state.massActionsModals.function
                denominacao.value = p_function.value.denominacao
                programSchema.value = store.state.massActionsModals.program_schema
                registerId.value = store.state.massActionsModals.registro_id
            }
            if (!value) {
                store.commit('massActionsModals/setProperty', [
                    {
                        key: 'showConfigModal',
                        value: false
                    }
                ])
                stringToBeSaved.value = ''
            }
        })

        function saveItems() {
            saveLoading.value = true

            let objeto = {};

            if (p_function.value.id_funcionalidade === 'c8d1240f-680d-49c2-bf18-64d53ef47430') {
                objeto = {
                    id_titulo_financeiro: registerId.value,
                    qr_code_pix: stringToBeSaved.value
                };
            } else if (p_function.value.id_funcionalidade === '1de8c5d7-fb67-41d8-b990-eb76ccc6c115') {
                objeto = {
                    id_titulo_financeiro: registerId.value,
                    codigo_barras_boleto: stringToBeSaved.value
                };
            }

            const payload = {
                solicita_tfa: false,
                id_programa: programSchema.value.id_programa,
                condicao_adicional: '',
                lista_id_empresa: JSON.parse(localStorage.getItem('companiesGlobalFilter')) ?? [],
                data_trabalho: JSON.parse(localStorage.getItem('dataTrabalho')),
                navegador: false,
                ordenacao: null,
                quantidade_registros: 0,
                pagina: 0,
                rota: programSchema.value.rota_backend + p_function.value.rota_backend,
                dados: {
                    [programSchema.value.sis_tabela.nome_tabela]: {
                        registros: [
                            objeto
                        ],
                    }
                }
            }
            sendFunctionRequest(payload)
                .then(async (res) => {
                    if (res.data.status) {
                        show.value = false
                        await store.state.massActionsModals.onSave(res.data.status)
                    }
                    saveLoading.value = false
                })
        }


        return {
            show,
            stringToBeSaved,
            title,
            denominacao,
            saveItems,
            saveLoading
        };
    },
}
</script>

<template>
    <b-modal size="xl" v-model="show" centered scrollable :title="denominacao">
        <div>
            <div class="row mb-4">
                <div class="col-12">
                    <form @submit.prevent>
                        <div class="input-group">
                            <input type="text" v-model="stringToBeSaved" required class="form-control"
                                placeholder="Digite aqui...">
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <template #footer>
            <div class="row w-100">
                <button v-if="saveLoading" type="button"
                    class="mt-2 ms-auto btn btn-primary shadow-sm col-12 col-md-3 btn-load"
                    style="height: 40px; min-width: 100px;">
                    <span class="d-flex align-items-center justify-content-center gap-2">
                        <span class="spinner-border flex-shrink-0" role="status"></span> Carregando
                    </span>
                </button>
                <button type="button" v-else class="mt-2 ms-auto btn btn-primary shadow-sm col-12 col-md-3"
                    style="height: 40px; min-width: 100px;" @click="saveItems"><i
                        class="ri-save-2-fill align-bottom me-1"></i> Salvar</button>
            </div>
        </template>
    </b-modal>
</template>