<template>
  <div :class="['card border card-border-success bg-light', this.$props.minimized ? 'chatbox' : '', this.$props.minimized ? showChatBoxPopup ? 'd-block' : 'd-none' : ''] ">
    <div class="card-header bg-success rounded-0 position-relative d-flex justify-content-between">
      <div class="flex-1">
        <h4 class="card-title mb-0 text-center text-white">Comentários</h4>
      </div>
      <button v-if="this.$props.minimized" class="btn btn-icon rounded-circle btn-sm btn-success" @click="showChatBoxPopup = !showChatBoxPopup" v-b-tooltip.hover.top="'ESC'">
        <i class="mdi mdi-close fs-24" />
      </button>
      <span v-if="!this.$props.minimized" class="position-absolute fs-15 translate-middle badge rounded-pill bg-danger chat-numbers" :title="chatMessagesData.length + ' Comentários'">{{chatMessagesData.length}}</span>
    </div>
    <div class="card-body p-0">
      <div class="position-relative user-chat" id="users-chat">
        <div class="chat-conversation p-3 p-lg-4" id="chat-conversation" data-simplebar ref="current" :style="{maxHeight:this.$props.maxHeight ?? '100%'}">
          <ul class="list-unstyled chat-conversation-list">
            <template v-for="(data, index) of chatMessagesData" :key="data.id">
              <div v-if="getDateSeparator(data, index > 0 ? chatMessagesData[index - 1] : null)" class="date-separator text-muted bg-light">
                {{ getDateSeparator(data, index > 0 ? chatMessagesData[index - 1] : null) }}
              </div>
              <li class="chat-list pt-1" :id="data.id">
                <div :class="['conversation-list', loggedUserId === data.user_id ? 'ms-auto' : '']">
                  <div class="user-chat-content">
                    <div :class="['ctext-wrap mb-0', loggedUserId === data.user_id ? 'flex-row-reverse' : '']">
                      <div class="ctext-wrap-content  shadow-sm">
                        <div class="mb-1 fw-bold mr-4" :style="{fontSize: '12px',color: users.find(u => u.value == data.name)?.color ?? '#4f4f4f'}">
                          {{ loggedUserId === data.user_id ? 'Você' : data.name }}
                        </div>

                        <div v-if="typeof (data.message) == 'string'">
                          <div v-if="/@/.test(data.message)" v-html="setMentionHtml(data.message)" />
                          <p v-else class="mb-0 ctext-content">
                            {{ data.message }}
                          </p>
                        </div>
                        <div v-else class="mb-0 ctext-content"
                          @click="imageSelected = !imageSelected, generateImage(chatMessagesData.indexOf(data))">
                          <img v-if="data.message.type == 'application/pdf'" class="img-thumbnail me-2" width="50"
                            src="../../assets/images/chat/pdf.png" data-holder-rendered="true" />
                          <img v-else class="img-thumbnail me-2" data-holder-rendered="true" frameBorder="0" scrolling="no"
                            width="50" src="../../assets/images/chat/png.png" />
                        </div>

                        <div v-if="data.edited_time" class="text-end text-muted" style="font-size:11px;">
                          Editado {{getEditedTime(data.edited_time)}}
                        </div>
                        <div v-else class="text-end text-muted" style="font-size:11px;">
                          {{ moment(new Date(data.time)).format('HH:mm') }}
                        </div>
                      </div>
                      <div class="dropdown align-self-start message-box-drop">
                        <a class="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true"
                          aria-expanded="false">
                          <i class="ri-more-2-fill" />
                        </a>
                        <div class="dropdown-menu">
                          <template v-for="button, index in buttons(data.user_id)" :key="index" href="javascript:void(0)">
                            <div v-if="button.active" class="list-group-item-action">
                              <a class="dropdown-item" role="button"
                                @click="action(button.denominacao, chatMessagesData.indexOf(data), data)">
                                <i :class="(button.icone ? button.icone : '') + ' align-middle me-2'" />
                                {{ button.denominacao }}
                              </a>
                            </div>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <b-modal v-model="imageSelected" fade scrollable centered size="lg"
                  style="background-color: rgba(0, 0, 0, 0.5);" hide-backdrop title="Arquivo"
                  header-bg-variant="soft-primary" ok-only ok-title="Sair" ok-variant="success">
                  <div class="modal-content">
                    <div class="modal-body">
                      <img v-if="tipo != 'application/pdf'" class="mw-100" :src="galeria" data-holder-rendered="true" />
                      <iframe v-else frameBorder="0" height="500px" width="100%" scrolling="auto" :src="galeria" />
                    </div>
                  </div>
                </b-modal>
              </li>
            </template>
          </ul>
        </div>

        <div id="dark-overlay" :class="'dark-overlay ' + (inEdition.data ? 'active' : '')"></div>
        <div v-if="inEdition.data" class="ctext-wrap-content shadow-sm ctext-wrap-edition-content" style="z-index:10;">
          <div v-if="typeof (inEdition.data.message) == 'string'">
            <div v-if="/@/.test(inEdition.data.message)" v-html="setMentionHtml(inEdition.data.message)" />
            <p v-else class="mb-0 ctext-content">
              {{ inEdition.data.message }}
            </p>
          </div>
          <div v-else class="mb-0 ctext-content"
            @click="imageSelected = !imageSelected, generateImage(chatMessagesData.indexOf(inEdition.data))">
            <img v-if="inEdition.data.message.type == 'application/pdf'" class="img-thumbnail me-2" width="50"
              src="../../assets/images/chat/pdf.png" data-holder-rendered="true" />
            <img v-else class="img-thumbnail me-2" data-holder-rendered="true" frameBorder="0" scrolling="no"
              width="50" src="../../assets/images/chat/png.png" />
          </div>
        </div>
      </div>
      <div class="border-top border-top-dashed chat-input-section p-3 p-lg-4 rounded-bottom">
        <form @submit.prevent="formSubmit">
          <div class="row g-0 align-items-center">
            <!-- <div class="col-auto">
              <div class="chat-input-links me-2">
                <div class="links-list-item">
                  <input type="file" multiple name="file" id="fileInputMessage" class="hidden-input" @change="onChange"
                    ref="file" accept=".pdf,.jpg,.jpeg,.png" hidden />
                  <button disabled type="button" @click="chooseFiles()" class="btn btn-link text-decoration-none emoji-btn"
                    id="emoji-btn">
                    <i class="ri-upload-2-fill align-middle" />
                  </button>
                </div>
              </div>
            </div> -->
            <div class="col relative">
              <button v-if="inEdition.data" @click="inEdition.data = null; form.message = ''" type="button" class="btn btn-sm btn-ghost-danger position-absolute" style="top: -26px; left: 0;">Cancelar edição</button>
              <Mentionable :keys="['@']" :items="users" offset="6" @open="onOpen">
                <div ref="inputCover" class="input-cover" v-html="setMentionHtml(form.message)" />
                <input id="form-input-text" type="text" v-model="form.message" class="form-control chat-input bg-light border-light comment"
                  maxlength="500" placeholder="Digite uma mensagem..." ref="comment" />
                <template #item="{ item }">
                  <div class="user">
                    <span class="font-weight-bold">
                      {{ item.value }}
                    </span>
                  </div>
                </template>
              </Mentionable>
              <div class="text-center">
                <p v-if="form.message" class="badge position-absolute" :class="{
                  'bg-success': form.message.length !== 500,
                  'bg-danger': form.message.length === 500,
                }">
                  {{ form.message.length }} / 500
                </p>
              </div>
            </div>
            <div class="col-auto">
              <div class="chat-input-links ms-2">
                <div class="links-list-item">
                  <button type="submit" class="btn btn-success chat-send waves-effect waves-light">
                    <i class="ri-send-plane-2-fill align-bottom" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <b-modal v-model="modalShow" fade scrollable centered size="lg" style="background-color: rgba(0, 0, 0, 0.5);"
    hide-backdrop title="Arquivos" header-bg-variant="soft-primary" ok-only
    :ok-title="files.length > 0 ? 'Enviar' : 'Sair'" ok-variant="success" @ok="formSubmit">
    <div v-if="files.length > 0">
      <div v-for="file in files" :key="file.name" class="preview-card border rounded mb-2">
        <div class="d-flex align-items-center p-2">
          <div v-if="(/\.(|doc|docx|xlsx|avi|ofx)$/).test(file.name)" class="border-dashed border p-2 mx-1 text-center">
              <p>Visualização indisponível</p>
          </div>
          <b-img v-else-if="file.type != 'application/pdf'" class="img-thumbnail me-2" style="max-height: 150px"
            :src="generateURL(file)" data-holder-rendered="true" />
          <iframe v-else class="img-thumbnail me-2" style="max-width: 150px" data-holder-rendered="true" frameBorder="0"
            scrolling="no" :src="generateURL(file)" />
          <div class="flex-grow-1">
            <div class="pt-1">
              <h5 class="fs-11 mb-1" data-dz-name="">
                {{ file.name }}
              </h5>
              <p class="fs-9 text-muted mb-0" data-dz-size="">
                <strong>{{ (file.size / 1024) / 1000 }}</strong> MB
              </p>
              <strong class="error text-danger" data-dz-errormessage="" />
            </div>
          </div>
          <div class="flex-shrink-0 ms-3">
            <b-button-group>
              <b-button class="btn btn-sm btn-danger" type="button" @click.prevent="remove(files.indexOf(file))">
                Excluir
              </b-button>
            </b-button-group>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="d-flex align-items-center p-2">
      <div class="text-center mt-sm-5 pt-4">
        <div class="mb-5 text-black-50">
          <h1 class="display-5">
            Nenhum Arquivo para ser mostrado aqui
          </h1>
        </div>
      </div>
    </div>
  </b-modal>
  <div class="chat-box-button" v-if="this.$props.minimized">
    <button class="btn btn-icon rounded-circle btn-lg btn-success" @click="showChatBoxPopup = !showChatBoxPopup">
      <span class="position-absolute fs-15 translate-middle badge rounded-pill bg-danger top-badge" style="z-index: 101;" :title="chatMessagesData.length + ' Comentários'">{{chatMessagesData.length}}</span>
      <i v-if="!showChatBoxPopup" class="mdi mdi-chat fs-24" />
      <i v-else class="mdi mdi-close fs-24" v-b-tooltip.hover.top="'Esc'"/>
    </button>
  </div>
</template>

<script>
import {
  required,
  helpers
} from "@vuelidate/validators"
import useVuelidate from "@vuelidate/core"
import appConfig from "../../../app.config"
import {
  chatData,
} from "../../views/apps/data"
import { Mentionable } from 'vue-mention'
import 'floating-vue/dist/style.css'
import moment from 'moment';

export default {
  setup() {
    return {
      moment,
      v$: useVuelidate()
    }
  },
  page: {
    title: "Chat",
    meta: [{
      name: "description",
      content: appConfig.description
    }],
  },
  props: {
    chatMessagesData: {
      type: Array,
      required: true
    },
    onSubmitNewMessage: {
      type: Function,
      required: true
    },
    onSubmitNewFile: {
      type: Function,
      required: false
    },
    onDeleteMessage: {
      type: Function,
      required: true
    },
    maxHeight: {
      type: String,
      required: false
    },
    minimized: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      showChatBoxPopup: true,
      loggedUserId: JSON.parse(localStorage.getItem('sis_config')).sis_usuario.id_usuario,
      modalShow: false,
      imageSelected: false,
      galeria: null,
      chatData: chatData,
      submitted: false,
      tipo: '',
      files: [],
      users: this.setUsers(),
      buttons: (user_id) => [
        { icone: 'ri-file-copy-line', denominacao: 'Copiar', active: true },
        { icone: 'ri-edit-line', denominacao: 'Editar', active: this.loggedUserId == user_id },
        { icone: 'ri-delete-bin-line', denominacao: 'Deletar', active: this.loggedUserId == user_id }
      ],
      form: {
        message: ''
      },
      inEdition: {
        data: null,
      },
      profile: require("@/assets/images/users/user-dummy-img.jpg")
    }
  },
  validations: {
    form: {
      message: {
        required: helpers.withMessage("É necessário uma mensagem.", required),
      },
    },
  },
  watch: {
    chatMessagesData: {
      handler() {
        var currentChatId = "users-chat"
        this.scrollToBottom(currentChatId)
      },
      deep: true
    }
  },
  methods: {
    onKeyUp(event) {
      if (event.key === 'Escape' && this.$props.minimized) {
        this.showChatBoxPopup = false
      }
    },
    getEditedTime(time) {
      if(moment(new Date()).format('DD/MM/YYYY') !== moment(new Date(time)).format('DD/MM/YYYY')){
        return moment(new Date(time)).format('DD/MM/YYYY HH:mm')
      }else{
        return moment(new Date(time)).format('HH:mm')
      }
    },
    getDateSeparator(actualMessage, previousMessage) {
      if(previousMessage == null){
        return moment(new Date(actualMessage.time)).format('DD/MM/YYYY')

      }else if(moment(new Date(actualMessage.time)).format('DD/MM/YYYY') != moment(new Date(previousMessage.time)).format('DD/MM/YYYY')){
        if(moment(new Date(actualMessage.time)).format('DD/MM/YYYY') == moment(new Date()).format('DD/MM/YYYY')){
          return 'Hoje'
        }else{
          return moment(new Date(actualMessage.time)).format('DD/MM/YYYY')
        }
      }
      return ''
    },
    setUsers() {
      const users =  [...new Set(this.chatMessagesData.map(message => message.name))].map(name => ({value: name}))
      users.forEach(user => {
        user.color = this.getRandomColor()
      })

      return users
    },
    getRandomColor(){
      var letters = '0123456789ABCDEF'
      var color = '#'
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)]
      }
      return color
    },
    onOpen(key) {
      this.items = key === '@' ? this.users : false
    },
    scrollToBottom(id) {
      setTimeout(function () {
        var simpleBar = document.getElementById(id).querySelector(
          "#chat-conversation .simplebar-content-wrapper") ?
          document.getElementById(id).querySelector(
            "#chat-conversation .simplebar-content-wrapper") : ''

        var offsetHeight = document.getElementsByClassName("chat-conversation-list")[0] ?
          document.getElementById(id).getElementsByClassName("chat-conversation-list")[0]
            .scrollHeight - window.innerHeight + 600 : 0

        if (offsetHeight)
          simpleBar.scrollTo({
            top: offsetHeight,
            behavior: "smooth"
          })
      }, 300)
    },

    formSubmit() {
      this.submitted = true

      if (this.files.length > 0) {
        for (let i = 0; i < this.files.length; i++) {
          const message = this.files[i]

          if(this.inEdition.data){
            this.$props.onSubmitNewFile(message, this.inEdition.data.id)
            this.inEdition.data = null
          }else{
            this.$props.onSubmitNewFile(message)
          }
        }
      } else {

        this.v$.$touch()

        if (this.v$.$invalid) {

          return

        } else {

          const message = this.form.message
          if(this.inEdition.data){
            this.$props.onSubmitNewMessage(message, this.inEdition.data.id)
            this.inEdition.data = null
          }else{
            this.$props.onSubmitNewMessage(message)
          }
        }
      }

      var currentChatId = "users-chat"
      this.scrollToBottom(currentChatId)

      this.modalShow = false
      this.submitted = false
      this.files = []
      this.form = {}
    },
    chooseFiles() {
      document.getElementById("fileInputMessage").click()
    },
    onChange(e) {
      this.$refs.file.files = e.target.files
      this.files.push(...this.$refs.file.files)
      this.fileSize(e)
      this.modalShow = true
    },
    fileSize(e) {
      for (let index = 0; index < this.files.length; index++) {
        if (this.files[index].size > 1024 * 1024 * 5) {
          e.preventDefault()
          this.files.splice(index, 1)
          alert(`Arquivo ${index + 1} muito grande! Máximo de 5MB`);
        }
      }
    },
    remove(i) {
      this.files.splice(i, 1)
    },
    generateURL(file) {
      if (file.type != 'application/pdf') {
        let fileSrc = URL.createObjectURL(file)
        setTimeout(() => {
          URL.revokeObjectURL(fileSrc)
        }, 1000)
        return fileSrc
      }
      else {
        let fileSrc = new String(URL.createObjectURL(file))
        setTimeout(() => {
          URL.revokeObjectURL(fileSrc)
        }, 1000)
        return fileSrc
      }
    },
    generateImage(i) {
      if (this.chatMessagesData[i].message.type == 'application/pdf') {
        this.galeria = new String(URL.createObjectURL(this.chatMessagesData[i].message))
        this.tipo = this.chatMessagesData[i].message.type
      }
      else {
        this.galeria = URL.createObjectURL(this.chatMessagesData[i].message)
        this.tipo = this.chatMessagesData[i].message.type
      }
    },
    editMessage(index, data) {
      this.inEdition.data = data
      document.getElementById("form-input-text").focus()
      this.form.message = data.message
    },
    action(nome, index, data) {
      switch (nome) {
        case 'Copiar':
          if (typeof (this.$props.chatMessagesData[index].message) == 'string') {
            navigator.clipboard.writeText(this.$props.chatMessagesData[index].message)
          }
          break
        case 'Deletar':
          this.onDeleteMessage(data)
          break
        case 'Editar':
          this.editMessage(index, data)
          break
      }
    },
    setMentionHtml(msg) {
      var htmlMessage = msg

      this.users.map(user => {
        var reg = new RegExp(`@${user.value}`, "igm")
        var match = reg.exec(htmlMessage)
        if (match) {
          htmlMessage = this.replaceAt(htmlMessage, match.index, reg.lastIndex, `@${user.value}`)
        }
      })
      return `<p class="mb-0 ctext-content">${htmlMessage}</p>`
    },
    replaceAt(str, start, end, replacement) {
      return `${str.substring(0, start)}<span class="text-info">${replacement}</span>${str.substring(end, str.length)}`
    }
  },
  unmounted() {
    document.removeEventListener('keyup', this.onKeyUp)
  },
  mounted() {
    var currentChatId = "users-chat"
    this.scrollToBottom(currentChatId)
    var userChatElement = document.querySelectorAll(".user-chat")
    document.querySelectorAll(".chat-user-list li a").forEach(function (item) {
      item.addEventListener("click", function () {
        userChatElement.forEach(function (elm) {
          elm.classList.add("user-chat-show")
        })

        var chatUserList = document.querySelector(".chat-user-list li.active")
        if (chatUserList) chatUserList.classList.remove("active")
        this.parentNode.classList.add("active")
      })
    })

    document.querySelectorAll(".user-chat-remove").forEach(function (item) {
      item.addEventListener("click", function () {
        userChatElement.forEach(function (elm) {
          elm.classList.remove("user-chat-show")
        })
      })
    })

    document.addEventListener('keyup', this.onKeyUp)
  },
  components: {
    Mentionable
  }
}
</script>

<style @scoped>
.user-chat{
  background-color: rgba(214,199,185,.4);
}

.chat-conversation .conversation-list{
  margin-bottom: 0;
}

.ctext-wrap-edition-content{
  z-index: 3;
  opacity: 1;
  position: absolute;
  bottom: 12px;
  left: 0;
  right: 0;
  padding: 12px 15px;
  border-radius: 2px;
  box-shadow: 2px 2px 10px;
  width: fit-content;
  margin: 0 22px;
  color: #000;
  background-color: #d9fdd3;
}

.dark-overlay{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  transition: all .3s ease-in-out;
}
.dark-overlay.active{
  z-index: 2;
  background-color: rgba(0,0,0,.7);
}

.mention-item {
  padding: 4px 10px;
  border-radius: 4px;
}

.mention-selected {
  background: #0AB39C;
  color: #ffffff;
}

.input-cover {
  z-index: 1;
  position: absolute;
  color: transparent;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  white-space: pre-wrap;
  overflow-wrap: anywhere;
  overflow-y: hidden;
  padding: 8px 14px;
  border: 1px solid transparent;
}

.chat-numbers {
  left: calc(50% + 80px);
  top: 0;
}

.chat-box-button {
  position: fixed;
  bottom: 30px;
  right: 100px;
  z-index: 100;
}

.chatbox {
  position: fixed;
  bottom: 40px;
  right: 150px;
  z-index: 100;
  width: 100%;
  max-width: 400px;
}
</style>
